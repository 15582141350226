import React, { useState } from 'react'
import * as S from './styles'
import { IconButton } from '@mui/material'
import { Label } from 'components/Label'
import { InputProps } from './typings'
import MaterialIcon from '@material/react-material-icon';
import { font } from 'utils/fonts'

export function Input({
  label,
  width,
  height,
  isPassword,
  type,
  hasError,
  maxLength,
  marginLeft,
  isSmall,
  customPadding,
  ...props
}:
  InputProps) {

  const [showPassword, setShowPassord] = useState(false)

  return (
    <div>
      <S.InputContainer marginLeft={marginLeft}>
        <Label marginBottom={'0.4rem'} fontSize={isSmall ? font.size14 : undefined} fontColor={isSmall ? '#767676' : undefined}>{label}</Label>
        <S.InputContent>
          <S.Input
            style={customPadding && {
              padding: '1.5rem 1rem'
            }}
            width={width}
            height={isSmall ? '1.9375rem' : height}
            hasError={hasError}
            type={isPassword ? !showPassword ? "password" : "text" : type || "text"}
            maxLength={maxLength ? maxLength : 100}
            isPassword={isPassword}
            {...props}
          />


          {isPassword &&
            <S.ShowPassContainer>
              <IconButton
                aria-label={showPassword ? "Mostrar senha" : "Ocultar senha"}
                onClick={() => setShowPassord(!showPassword)}
                edge="end"
              >
                <MaterialIcon icon={showPassword ? "visibility_off" : "visibility"} />
              </IconButton>
            </S.ShowPassContainer>
          }
        </S.InputContent>
      </S.InputContainer>
    </div>
  )
}