import React from "react";

import { FiUser } from "react-icons/fi";
import { IoExitOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

//import UserImage from '../../images/user.svg'

import { useAuth } from "../../hooks/useAuth/useAuth";

import avatar from "assets/icons/avatar.svg";

import { IUser } from "hooks/useAuth/typings";

import * as S from "./styles";
import { Popover, Typography } from "@mui/material";

interface Props {
    id: string;
    open: boolean;
    anchorEl: any;
    handleClose: () => void;
}

const DropUserInformations: React.FC<Props> = ({
    id,
    open,
    anchorEl,
    handleClose,
}) => {
    const userInfo: IUser = JSON.parse(localStorage.getItem("u") as any);
    // const { firstName, avatar, email } = useAuth();

    return (
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            style={{
                marginTop: "10px",
                borderRadius: "8px",
            }}
        >
            <Typography
                sx={{ p: 0 }}
                style={{
                    height: "161px",
                    width: "400px",
                    borderRadius: "8px",
                }}
            >
                <S.DropDownUser>
                    <div className="dropdownUserContainer">
                        <div className="dropdownUserBox">
                            <div className="head">
                                <img
                                    src={
                                        userInfo.avatar
                                            ? `data:image/jpeg;base64, ${userInfo.avatar}`
                                            : avatar
                                    }
                                    alt="foto do usuário"
                                />
                                <span>
                                    <h3>{userInfo.firstName}</h3>
                                    <small>{userInfo.email}</small>
                                </span>
                            </div>
                            {/* <div className="contentNotifi">
                        <Link to="/my-profile">
                            <FiUser size={25} />
                            <span>
                                <h4>
                                    Meu Perfil
                                </h4>
                            </span>
                        </Link>
                    </div> */}
                            <div className="contentNotifi red">
                                <div
                                    className="logout"
                                    onClick={() => {
                                        localStorage.clear();
                                        window.location.replace("/");
                                    }}
                                >
                                    <IoExitOutline size={25} />
                                    <span>
                                        <h4>Sair</h4>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </S.DropDownUser>
            </Typography>
        </Popover>
    );
};

export default DropUserInformations;
