import styled from 'styled-components'
import { InputProps } from './typings'

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: '2rem';
  margin-left: ${(props: InputProps) => props.marginLeft};
`

export const InputContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`

export const Input = styled.input<InputProps>`
  background: ${props => props.theme.background};
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  border-radius: 0.25rem;
  border: none;
  outline-color: ${props =>
    props.hasError ? props.theme.error : props.theme.primary};
  padding: 0 1rem 0 1rem;
  padding-right: ${p => p.isPassword && '3.1rem'};
`

export const ShowPassContainer = styled.div`
  position: absolute;
  margin-right: 1rem;
`