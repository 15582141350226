import React, { useState, useEffect, useCallback } from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    useLoadScript,
} from "@react-google-maps/api";
import { Container, UserList } from "./styles";
import api from "services/api";
import { Modal } from "../Modal";
import iconMarker from "assets/icons/rec.svg";
import { useAlert } from "context/useAlert";
import { LoadingModal } from "components/LoadingModal";
import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import ProfileIMG from "../../assets/icons/profile.png";

const initialPosition = {
    lat: -15.781195,
    lng: -47.894799,
};

const globalBounds = new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(-85, -180),
    new window.google.maps.LatLng(85, 180)
);

function GoogleMaps() {
    const { isLoaded } = useLoadScript({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyBxDOf5OwSa5J24JYOs5tKjQGVkP0PVLl0",
    });

    const { showAlert } = useAlert();

    const [map, setMap] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const [users, setUsers] = useState<any[]>([]);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [cityListed, setCityListed] = useState<string>("");

    const fetchUsers = async (city: string) => {
        try {
            setLoading(true);
            const { data } = await api.get(
                `/users?city=${city}&limit=99999999`
            );
            setUsers(data.users);
            setLoading(false);
            setTotalUsersInCity(data.users.length);
            return true;
        } catch (e: any) {
            showAlert({
                severety: "error",
                title: e?.response?.data?.error,
            });
            return false;
        }
    };

    // const addMarkers = async (map) => {
    //     try {
    //         const { data } = await api.get("/dashboard/usageAnalysis");
    //         const locations = data?.frequency?.map((frequency) => {
    //             console.log(frequency);
    //             return {
    //                 city: frequency.city,
    //                 count: frequency.count,
    //                 lat: parseFloat(frequency.latitude),
    //                 lng: parseFloat(frequency.longitude),
    //             };
    //         });

    //         const markers = locations.forEach((loc) => {
    //             if (
    //                 typeof loc.lat === "number" &&
    //                 typeof loc.lng === "number"
    //             ) {
    //                 const marker = new google.maps.Marker({
    //                     position: {
    //                         lat: loc.lat,
    //                         lng: loc.lng,
    //                     },
    //                     title: `${loc.count}`,
    //                     label: {
    //                         text: `${loc.count}`,
    //                         className: "mapMarker",
    //                         color: "white",
    //                         fontSize: "1rem",
    //                         fontWeight: "900",
    //                     },
    //                     icon: {
    //                         url: iconMarker,
    //                     },
    //                     map: map,
    //                 });

    //                 marker.addListener("click", () => {
    //                     setCityListed(loc.city);
    //                     fetchUsers(loc.city).then(() => setModalOpen(true));
    //                 });
    //             }
    //         });
    //         new MarkerClusterer({
    //             markers,
    //             map,
    //             algorithm: new SuperClusterAlgorithm({ radius: 200 }),
    //         });
    //     } catch (e: any) {
    //         showAlert({
    //             severety: "error",
    //             title: e?.response?.data?.error,
    //         });
    //     }
    // };

    const [totalUsersInCity, setTotalUsersInCity] = useState<number | null>(
        null
    );

    async function addMakers(map) {
        try {
            const { data } = await api.get("/dashboard/usageAnalysis");

            console.log("oi", data);
            const locations = data?.frequency?.map((frequency) => {
                return {
                    city: frequency.city,
                    count: frequency.count,
                    lat: parseFloat(frequency.latitude),
                    lng: parseFloat(frequency.longitude),
                };
            });

            const markers = locations.map((loc) => {
                console.log(loc.count);
                const marker = new google.maps.Marker({
                    position: {
                        lat: loc.lat,
                        lng: loc.lng,
                    },
                    title: `${loc.count}`,
                    label: {
                        text: `${loc.count}`, // Aqui você define o número total de usuários como o texto do marcador
                        className: "mapMaker",
                        color: "white",
                        fontSize: "1rem",
                        fontWeight: "900",
                    },
                    icon: {
                        url: iconMarker,
                    },
                });

                marker.addListener("click", () => {
                    setCityListed(loc.city);
                    fetchUsers(loc.city).then(() => setModalOpen(true));
                });

                return marker;
            });

            new MarkerClusterer({
                markers,
                map,
                algorithm: new SuperClusterAlgorithm({ radius: 200 }),
                renderer: {
                    render: (cluster, stats, map) => {
                        const clusts = cluster.markers;
                        clusts.map((clus) => {
                            // console.log(
                            //     // @ts-expect-error
                            //     clus.position.lat(),
                            //     // @ts-expect-error
                            //     clus.position.lng()
                            // );
                        });

                        const latValue = cluster.markers
                            // @ts-expect-error
                            .map((mark) => mark?.position.lat())
                            .reduce((sum, lat) => {
                                return sum + parseInt(lat);
                            }, 0);

                        const lgnValue = cluster.markers
                            // @ts-expect-error
                            .map((mark) => mark?.position.lng())
                            .reduce((sum, lng) => {
                                return sum + parseInt(lng);
                            }, 0);

                        const number = cluster.markers
                            // @ts-expect-error
                            .map((mark) => mark?.label.text)
                            .reduce((sum, number) => {
                                return sum + parseInt(number);
                            }, 0);
                        return new google.maps.Marker({
                            position: {
                                lat: latValue / clusts.length,
                                lng: lgnValue / clusts.length,
                            },
                            title: `${number}`,
                            label: {
                                text: `${number}`,
                                className: "mapMaker",
                                color: "white",
                                fontSize: "1rem",
                                fontWeight: "900",
                            },
                            icon: {
                                url: iconMarker,
                                // size: {
                                //   width: 70,
                                //   height: 70,
                                //   equals: () => null
                                // }
                            },
                        });
                    },
                },
            });
        } catch (e: any) {
            showAlert({
                severety: "error",
                title: e?.response?.data?.error,
            });
        }
    }

    const onLoad = useCallback((map) => addMakers(map), []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    return (
        <Container>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{
                        width: "100%",
                        height: "100%",
                    }}
                    center={initialPosition}
                    zoom={5}
                    onLoad={onLoad}
                    options={{
                        maxZoom: 12,
                        minZoom: 3,
                        restriction: {
                            latLngBounds: globalBounds,
                        },
                    }}
                >
                    {users.map((user) => (
                        <Marker
                            key={user._id}
                            position={{
                                lat: Number(user.latitude),
                                lng: Number(user.longitude),
                            }}
                            icon={{
                                url: iconMarker,
                            }}
                            onClick={() => {
                                setCityListed(user.city);
                                setModalOpen(true);
                            }}
                        />
                    ))}
                </GoogleMap>
            ) : (
                <></>
            )}
            {modalOpen && (
                <Modal
                    title={`Usuários em ${cityListed} (${totalUsersInCity} usuários)`}
                    icon="people"
                    setState={setModalOpen}
                    state={modalOpen}
                    container={
                        <UserList>
                            {users?.map((user: any) => (
                                <div className="item" key={user._id}>
                                    {user.file ? (
                                        <img src={user.file} />
                                    ) : (
                                        <img src={ProfileIMG} />
                                    )}
                                    <span>{user.fullName}</span>
                                </div>
                            ))}
                        </UserList>
                    }
                />
            )}
            <LoadingModal show={loading} />
        </Container>
    );
}
export default React.memo(GoogleMaps);
