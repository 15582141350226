import React, { useRef, useState } from "react";
import * as S from "./styles";
//import { UserInfo } from 'components/UserInfo'
import { Button, Tooltip } from "@mui/material";

import logo from "assets/icons/logo.svg";
import avatar from "assets/icons/avatar.svg";
import MaterialIcon from "@material/react-material-icon";
import { IUser } from "hooks/useAuth/typings";
import { useNavigate } from "react-router-dom";

import DropUserInformations from "../dropdownUserInformation/index";
import { IoCloseOutline } from "react-icons/io5";
import { FaBars } from "react-icons/fa";

interface NavbarProps {
    setIsOpen: (e: any) => void;
    isOpen: boolean;
}

export function Navbar({ isOpen, setIsOpen }: NavbarProps) {
    const userInfo: IUser = JSON.parse(localStorage.getItem("u") as any);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <S.Container>
            <S.CollapseButton onClick={() => setIsOpen((e: boolean) => !e)}>
                <IoCloseOutline
                    title="Fechar menu"
                    className={`icon ${isOpen ? "opened" : "closed"}`}
                />
                <FaBars
                    title="Abrir menu"
                    className={`icon ${isOpen ? "closed" : "opened"}`}
                />
            </S.CollapseButton>
            <S.Logo src={logo} onClick={() => navigate("/")} />
            <S.RightContainer>
                <S.User>
                    <S.Avatar
                        src={
                            userInfo.avatar
                                ? `data:image/jpeg;base64, ${userInfo.avatar}`
                                : avatar
                        }
                    />
                    <Button onClick={(e) => handleClick(e)}>
                        <S.Text>Olá,</S.Text>
                        <S.UserName>{userInfo.firstName}</S.UserName>
                        <MaterialIcon icon={"arrow_drop_down"} />
                    </Button>
                </S.User>
                <DropUserInformations
                    id={id}
                    open={open}
                    handleClose={handleClose}
                    anchorEl={anchorEl}
                />
            </S.RightContainer>
        </S.Container>
    );
}
