import styled from 'styled-components'

export const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .children-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1.5rem 1.5rem 0 1.5rem;
    margin-bottom: 1.5rem;
  }
`
