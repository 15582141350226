import React from 'react'
import MaterialIcon from "@material/react-material-icon";
import * as S from './styles'
import { IconButtonProps } from "./typings";

export function IconButton({ icon, onClick, customIcon }: IconButtonProps) {
  return (
    <S.IconButton onClick={onClick}>
      {
        customIcon ? customIcon : <MaterialIcon icon={icon} />
      }
    </S.IconButton>
  )
}