import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
  ${normalize}
  


  body {
  padding: 0;
  padding-right: 0!important;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  background: ${(props) => props.theme.background};
  width: 100%;
  height: 100%;
  }

  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  label, 
  a, 
  span {
    font-family: 'Poppins', sans-serif !important;
  }

  ::-moz-selection {
  background: ${(props) => props.theme.primary};
}

::selection {
  background: ${(props) => props.theme.primary};
}


// ##### MUI #####
.css-1idz92c-MuiCircularProgress-svg {
  color: ${(props) => props.theme.surface};
}

.MuiPopover-paper, .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
  border-radius: 8px!important;
}
  .skeleton {
  *, & {
    width: 100%;
    flex: 1;
  }
}
`;
