import { SquareAvatar } from 'components/Avatar'
import { Status } from 'components/Status'
import React, { useCallback, useEffect, useState } from 'react'
import * as Styles from './styles'

import { getEvent } from "routes/events";

import noAvatarIcon from 'assets/icons/avatar.svg'
import { useParams } from 'react-router-dom';
import { Table } from 'components/Table';

export interface EventsUsersProps {
    className?: string
}

export const EventsUsers = (props: EventsUsersProps) => {

    const { id } = useParams()

    const [eventUsers, setEventUsers] = useState<any[]>([])

        const columns = [
            {
                field: 'name',
                title: 'Nome do evento',
                // width: 172,
                onRenderContent: (data: any) => (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1.5625rem'
                    }}>
                        <SquareAvatar
                            alt={`Imagem de ${data.participant.fullName}`}
                            src={data.participant.file ? `${data.participant.file}` : noAvatarIcon}
                        />
                        <span>{data.participant.fullName}</span>
                    </div>
                )
            },
            {
                field: 'pais',
                title: 'País',
                onRenderContent: (data: any) => (
                    data.participant.country
                )
                // width: 100,
            },
            {
                field: 'city',
                title: 'Cidade',
                onRenderContent: (data: any) => (
                    data.participant.city
                )
                // width: 50,
            },
            {
                field: 'state',
                title: 'Estado',
                onRenderContent: (data: any) => (
                    data.participant.state
                )
                // width: 50,
            },
            {
                field: 'sex',
                title: 'Sexo',
                onRenderContent: (data: any) => (
                    data.participant.sex
                )
                // width: 50,
            },
            {
                field: 'status',
                title: 'Status',
                onRenderContent: (data: any) => (
                    <Status
                        isDisabled={false}
                        title={data.participant.typeSubs}
                    />
                )
                // width: 50,
            }
        ]

        const getEventInformations = useCallback(async () => {
            const response = await getEvent(id)
            setEventUsers(response.participants)
        }, [])

        useEffect(() => {
            const isMounted = true

            if (isMounted && id) getEventInformations()

            return () => { }

        }, [getEventInformations])

    if (id) {
        return (
            <Styles.Container className={props.className} >
                <Table
                    title={'Participantes'}
                    subtitle={'Visualize os participantes do evento'}
                    columns={columns}
                    withoutPagination
                    data={eventUsers}
                    withoutFilter
                    withoutButtons
                />
            </Styles.Container>
        )
    } else {
        return (
            <></>
        )
    }
}