import axios from 'axios'
import cogoToast from 'cogo-toast'

const token =
  localStorage.getItem('u') &&
  String(JSON.parse(localStorage.getItem('u') as any).t)

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  }
})

// api.interceptors.request.use(config => {
//   return config
// })

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const err = error.response
    if ((err.status === 401 && err.config && !err.config.__isRetryRequest) || err.data.error == "jwt expired") {
      window.location.replace('/')
      localStorage.clear()
      cogoToast.warn('Sessão expirada')
    } else {}
    return Promise.reject(error)
  }
)

export default api
