import React, { useEffect, useState } from 'react'
import { Navbar } from 'components/Navbar'
import * as S from './styles'
import { Sidebar } from 'components/Sidebar'

export default function Layout({ children }: any) {

  const [open, setOpen] = useState(false)

  return (
    <div>
      <Navbar isOpen={open} setIsOpen={setOpen} />
      <S.Layout>
        <Sidebar open={open} />
        <main className="children-container">{children}</main>
      </S.Layout>
    </div>

  )
}


