import styled from 'styled-components'
import { font } from 'utils/fonts'
import { StatusProps } from './typings'

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.isDisabled ? props.theme.background : props.theme.bg};
  width: 5rem;
  height: 1.625rem;
  border-radius: 0.375rem;

  span {
    font-size: ${font.size11};
    font-weight: ${font.weigthNormal};
    color: ${props =>
      props.isDisabled ? props.theme.disabledText : props.theme.primary};
  }
`
