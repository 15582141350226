import styled from 'styled-components'

export const Modal = styled.div`
  max-width: 95vw;
  height: auto;
  background: ${props => props.theme.surface};
  padding: 2.4375rem 0 2.125rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.9375rem;

  @media screen and (min-width: 768px) {
    width: calc(40vw + 100px);
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.25rem;
  background: ${props => props.theme.primary};
  border-radius: 100%;
  width: 4.3125rem;
  height: 4.3125rem;
  cursor: default;

  .material-icons {
    color: ${props => props.theme.surface};
    transform: scale(150%);
  }
`

export const Titles = styled.div`
  height: auto;
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2.1875rem;
`
