import React, { useEffect, useState } from "react";

import { Table } from "components/Table";
import { getEvents, toggleEventStatus } from "routes/events";
import { SquareAvatar } from "components/Avatar";
import * as S from "./styles";

import noAvatarIcon from "assets/icons/avatar.svg";
import { Status } from "components/Status";
import { IconButton } from "components/IconButton";
import { FiPower } from "react-icons/fi";
import { Link, Route, useNavigate } from "react-router-dom";
import { FaPen } from "react-icons/fa";
import { Modal } from "components/Modal";
import { RiShutDownLine } from "react-icons/ri";
import { useAlert } from "context/useAlert";
// import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

export function Events() {
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const [eventsData, setEventsData] = useState([]);
    const [toInactive, setToInactive] = useState<any>(null);
    const [openModal, setOpenModal] = useState(false);

    const columns = [
        {
            field: "name",
            title: "Nome do evento",
            // width: 172,
            onRenderContent: (data: any) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "1.5625rem",
                    }}
                >
                    <SquareAvatar
                        alt={`Imagem de ${data.name}`}
                        src={data.file ? `${data.file}` : noAvatarIcon}
                    />
                    <span>{data.name}</span>
                </div>
            ),
        },
        {
            field: "date",
            title: "Data",
            onRenderContent: (data: any) =>
                data.date.substring(0, 10).split("-").reverse().join("/"),
            // width: 100,
        },
        {
            field: "city",
            title: "Cidade",
            onRenderContent: (data: any) => data.local.city,
            // width: 50,
        },
        {
            field: "state",
            title: "Estado",
            onRenderContent: (data: any) => data.local.state,
            // width: 50,
        },

        {
            field: "numberParticipant",
            title: "Numero de participantes",
            // width: 50,
        },

        {
            field: "status",
            title: "Status",
            onRenderContent: (data: any) => (
                <Status
                    isDisabled={data.status === 0}
                    title={data.status === 1 ? "Ativo" : "Inativo"}
                />
            ),
            // width: 50,
        },
        {
            field: "Ações",
            title: "Ações",
            onRenderContent: (data: any) => (
                <>
                    <IconButton
                        icon="edit"
                        onClick={() => navigate(`/eventos/editar/${data._id}`)}
                    />

                    <IconButton
                        customIcon={
                            <S.ContainerIcon>
                                <FiPower />
                            </S.ContainerIcon>
                        }
                        onClick={() => {
                            setToInactive(data);
                            setOpenModal(true);
                        }}
                    />
                </>
            ),
        },
    ];

    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
        //count: 0
    });

    const [count, setCount] = useState(0);

    const handleToggleStatus = (id: number) => {
        toggleEventStatus(id).then((res) => {
            setToInactive(null);
            setOpenModal(false);
            if (res) {
                showAlert({
                    severety: "success",
                    title: "Status do evento editado com sucesso.",
                });
            }
            getEvents(pagination.page, pagination.limit).then((response) => {
                setEventsData(response?.events);
            });
        });
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPagination((prevState) => ({
            ...prevState,
            page: newPage + 1,
        }));
        getEvents(newPage + 1, pagination.limit).then((response) =>
            setEventsData(response?.events)
        );
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPagination({
            page: 1,
            limit: parseInt(event.target.value, 10),
        });
        getEvents(1, parseInt(event.target.value, 10)).then((response) =>
            setEventsData(response?.events)
        );
    };

    useEffect(() => {
        getEvents(1, 6).then((response) => {
            setEventsData(response?.events);
            setCount(response?.count);
        });
    }, []);

    // count={TablePaginationActionsProps?.count}
    //         page={TablePaginationActionsProps?.page}
    //         onPageChange={TablePaginationActionsProps?.onPageChange}
    //         rowsPerPage={TablePaginationActionsProps?.rowsPerPage}
    //         onRowsPerPageChange={
    //           TablePaginationActionsProps?.handleChangeRowsPerPage
    //         }
    //         labelRowsPerPage={'Linhas por página:'}

    return (
        <>
            <Table
                title={"Eventos"}
                subtitle={"Visualize os eventos da plataforma"}
                columns={columns}
                TablePaginationActionsProps={{
                    page: pagination.page - 1,
                    count: count,
                    rowsPerPage: pagination.limit,
                    handleChangeRowsPerPage: handleChangeRowsPerPage,
                    onPageChange: handleChangePage,
                }}
                data={eventsData}
                withoutFilter
                primaryButton={{
                    title: "Cadastrar Evento",
                    onClick: () => navigate("/cadastrar-evento"),
                }}
            />
            {openModal && toInactive && (
                <Modal
                    title={toInactive?.status === 1 ? "INATIVAR" : "ATIVAR"}
                    icon=""
                    customIcon={
                        <RiShutDownLine
                            size={40}
                            title="Alterar Status"
                            color="#FFF"
                        />
                    }
                    state={openModal}
                    setState={setOpenModal}
                    onClick={() => handleToggleStatus(toInactive._id)}
                    container={
                        <p
                            style={{
                                margin: "1rem 0 0",
                                textAlign: "center",
                            }}
                        >
                            Tem certeza que deseja{" "}
                            {toInactive?.status === 1 ? "inativar" : "ativar"}{" "}
                            {toInactive.name}? <br />
                            Será possível voltar ao estado atual posteriormente.
                        </p>
                    }
                />
            )}
        </>
    );
}
