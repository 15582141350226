import React from "react";
import {
    BrowserRouter as Router,
    Routes as RoutesApp,
    Route,
    Navigate,
} from "react-router-dom";
import Layout from "components/Layout";
import { useAuth } from "hooks/useAuth/useAuth";

/* #### Pages #### */
import { Login } from "./pages/Login";
import { Users } from "pages/Users";
import { Administration } from "pages/Administration";
import { UseAnalyze } from "pages/UseAnalyze";
import { Events } from "pages/Events";
import { DetailsEvent } from "pages/DetailsEvent";
import { RegisterEvent } from "pages/RegisterEvent";
import { EventsUsers } from "components/EventsUsers";
import { Dashboard } from "pages/Dashboard";
import { RegisterADM } from "pages/RegisterAdm";
import { Profile } from "pages/Profile";

export default function Routes() {
    const { isAuthenticated } = useAuth();

    const ProtectedRoute = ({ children }: any) => {
        if (!isAuthenticated) {
            return <Navigate to="/" replace />;
        }
        return children;
    };

    return (
        <Router>
            <RoutesApp>
                <Route
                    path="/"
                    element={
                        isAuthenticated ? (
                            <Navigate to="/inicio" replace />
                        ) : (
                            <Login />
                        )
                    }
                />

                <Route
                    path="/usuarios"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Users />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/administracao"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Administration />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/analise"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <UseAnalyze />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/events/admin"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Events />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/eventos/editar/:id"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <DetailsEvent />
                                <EventsUsers />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/cadastrar-evento"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <DetailsEvent />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/cadastrar-adm"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <RegisterADM />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/editar-adm/:id"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <RegisterADM />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/inicio"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Dashboard />
                            </Layout>
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/usuarios/:id"
                    element={
                        <ProtectedRoute>
                            <Layout>
                                <Profile />
                            </Layout>
                        </ProtectedRoute>
                    }
                />
            </RoutesApp>
        </Router>
    );
}
