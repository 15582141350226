export const SidebarOptions = [
    {
        icon: "dashboard",
        title: "Início",
        path: "/inicio",
    },
    {
        icon: "person",
        title: "Usuários",
        path: "/usuarios",
    },
    {
        icon: "key",
        title: "Administração",
        path: "/administracao",
    },
    {
        icon: "visibility",
        title: "Análise de Uso",
        path: "/analise",
    },
    // {
    //   icon: "event",
    //   title: "Eventos",
    //   path: "/eventos",
    // },
];
