import React, { useState } from "react";
import * as S from "./styles";
import { UserProps } from "./typings";

import { produce } from "immer";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { useAuth } from "hooks/useAuth/useAuth";
import logo from "assets/icons/logo.svg";

export function Login() {
    const { authenticate } = useAuth();

    const [user, setUser] = useState<UserProps>({
        email: "teste@swagger.com",
        password: "Evolution@2022",
    });
    const [isLoading, setIsLoading] = useState(false);

    async function handleLogin() {
        setIsLoading(true);
        authenticate(user.email, user.password)
            .then(() => {
                window.location.reload();
            })
            .catch(() => setIsLoading(false));
    }

    return (
        <S.Login>
            <S.BoxContainer>
                <S.Logo src={logo} alt="Struko" />
                <S.Inputs>
                    <Input
                        width={"25.625rem"}
                        height={"3rem"}
                        label="Login"
                        placeholder="Informe o seu email"
                        //value={user.email}
                        onChange={(e: any) =>
                            setUser(
                                produce(user, (draft) => {
                                    draft.email = e.target.value;
                                })
                            )
                        }
                    />

                    <Input
                        width={"25.625rem"}
                        height={"3rem"}
                        isPassword
                        label="Senha"
                        placeholder="Informe a sua senha"
                        //value={user.password}
                        onChange={(e: any) =>
                            setUser(
                                produce(user, (draft) => {
                                    draft.password = e.target.value;
                                })
                            )
                        }
                    />
                    <Button
                        title="Entrar"
                        width="27.6rem"
                        height="3.2rem"
                        marginTop={"1.75rem"}
                        marginBottom={"1rem"}
                        isLoading={isLoading}
                        onClick={() => !isLoading && handleLogin()}
                    />
                </S.Inputs>
            </S.BoxContainer>
        </S.Login>
    );
}
