import styled from 'styled-components'
import { font } from 'utils/fonts'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.surface};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;

  .btnsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    button {
      border: none;
      outline: none;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      height: 2.5rem;
      border-radius: 6px;
    }

    .secondaryButton{
      background-color: #FFF;
      outline: 1px solid ${(props) => props.theme.primary};
      transition: all 0.2s ease-in-out;
      color: ${(props) => props.theme.primary};

      &:hover{
        filter: brightness(0.95)
      }
    }

    .primaryButton {
      background-color: ${props => props.theme.primary};
      color: ${(props) => props.theme.bg};
      transition: all 0.2s ease-in-out;

      &:hover{
        filter: brightness(0.95)
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media screen and (max-width: 768px){
    &, .btnsContainer{
      flex-direction: column;
      row-gap: 1rem;
      text-align: center;
    }

    .btnsContainer, &{
      button {
        width: 100%;
      }
    }
  }
`

export const Titles = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: ${font.size24};
    font-weight: ${font.weightStrong};
    color: ${props => props.theme.header};
    margin-bottom: 0;
  }

  .subTitle {
    font-size: ${font.size12};
    font-weight: ${font.weigthNormal};
    color: ${props => props.theme.subtitle};
    /* margin-top: -1.5rem; */
  }
`
export const Children = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-left: 1.8125rem;

  .return {
    &, svg, img {
      color: ${({theme}) => theme.primary};
      cursor: pointer;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: unset;
  }
`