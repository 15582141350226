import React, { useState, useEffect, useRef } from "react";
import { Input } from 'components/Input'
import { SelectLabel } from "components/SelectLabel";

import { Container } from 'components/Container';
import { useNavigate, useParams } from "react-router-dom";
import { getCities, getStates } from "routes/ibge";
import { getEvent, handleEditEvent, handleRegisterEvent } from "routes/events";
import fileToBase64 from "utils/fileToBase64";
import produce from "immer";

import * as S from '../../components/ConsultEditEvent/styles'
import { MyDropzoneHomolog } from "components/Dropzone";
import { IoSearch } from "react-icons/io5";
import { SelectNome } from "components/SelectNome";
import { AiOutlineCheck } from "react-icons/ai";
import { Modal } from "components/Modal";
import {FaChevronLeft, FaPen } from "react-icons/fa";

export function DetailsEvent() {
    const { id } = useParams()

    const [isEditable, setIsEditable] = useState(id ? false : true)

    // const [data, setData] = useState<any>({
    //     date: '',
    //     file: null,
    //     hour: '',
    //     local: {
    //         botough: '',
    //         city: '',
    //         complement: '',
    //         country: '',
    //         number: '',
    //         state: '',
    //         street: '',
    //         zipCode: ''
    //     },
    //     name: '',
    //     numberParticipant: null,
    //     status: null,
    //     __v: null,
    //     _id: ''
    // })

    const [location, setLocation] = useState({
        states: [],
        cities: [],
    })

    const [image, setImage] = useState<any>(null)

    const [nome, setNome] = useState('')
    const [eventImage, setEventImage] = useState<any>(null)
    const [eventDate, setEventDate] = useState<any>('')
    const [eventHour, setEventHour] = useState<any>('')
    const [borough, setBorough] = useState<any>('')
    const [eventComplement, setEventComplement] = useState<any>('')
    const [eventCountry, setEventCountry] = useState<any>('')
    const [eventNumber, setEventNumber] = useState<any>('')
    const [eventCity, setEventCity] = useState<any>('')
    const [eventState, setEventState] = useState<any>('')
    const [eventStreet, setEventStreet] = useState<any>('')
    const [eventZipCode, setEventZipCode] = useState<any>('')

    const [isLoading, setIsLoading] = useState(true)

    const [openModal, setOpenModal] = useState(false)

    const history = useNavigate()

    const [eventResponse, setEventResponse] = useState<any>(null)
    const [already, setAlready] = useState(false)

    const filePickerRef: any = useRef()

    function getStateLabel(stateId: number) {
        const state: any = location.states.find((item: any) => item.value == stateId)
        return state?.label
    }

    function getCityLabel(cityId: number) {
        const city: any = location.cities.find((item: any) => item.value == cityId)
        return city?.label
    }

    function handleGetCities(stateId: number) {
        getCities(stateId).then((response: any) => {
            const citiesList = response.map((item: any) => {
                return {
                    value: item.id,
                    nome: item.nome
                }

            })
            setLocation(produce(location, draft => {
                draft.cities = citiesList
            }))

            setEventState(stateId)
            setEventCity(response[0].id)
        })
    }

    const updateEvent = async () => {
        const fd = new FormData()
        fd.append('name', nome)
        fd.append('date', eventDate)
        fd.append('hour', eventHour)
        fd.append('zipCode', eventZipCode)
        fd.append('street', eventStreet)
        fd.append('number', eventNumber)
        fd.append('borough', borough)
        fd.append('city', eventCity)
        fd.append('state', eventState)
        fd.append('country', eventCountry)
        fd.append('complement', eventComplement)
        fd.append('file', eventImage)
        if(id){
            try {
                await handleEditEvent(id, fd)
                return true
            } catch (e) {
            }
        } else {
            let alright = false
            await handleRegisterEvent(fd).then(res => {
                alright = res
            })
            return alright
        }
    }

    async function convertToBase64() {
        let image64 = await fileToBase64(image)
        setEventImage(image64)
    }

    useEffect(() => {
        if(id) {
            convertToBase64()
        }
    }, [image])

    useEffect(() => {
        getStates().then((response: any) => {
            console.log(response)
            const statesList = response.map((item: any) => {
                return {
                    value: item.id,
                    label: item.sigla
                }
            })

            getCities(response[0].id).then((cities: any) => {
                const citiesList = cities.map((item: any) => {
                    return {
                        value: item.id,
                        label: item.nome
                    }

                })
                setLocation({
                    states: statesList,
                    cities: citiesList,
                })
                // setData(produce(data, (draft: any) => {
                //     draft.state = response[0].id;
                //     draft.city = cities[0].id
                // }))
            })
        })
    }, [])

    function findState(response: any) {

        const result: any = location.states.find((item: any) => item.label == response.local.state)

        return result.value
    }

    function findCity(response: any) {

        const result: any = location.cities.find((item: any) => item.label == response.local.city)

        return result.value
    }

    async function Testando() {
        const response = await getEvent(id)

        // const city = await findCity(response)

        // const eventData = {
        //     ...response,
        //     date: response.date.substring(0, 10),
        //     hour: response.hour.substring(11, 19),
        //     local: {
        //         ...response.local,
        //         state,
        //         // city
        //     }
        // }
        setNome(response.event.name)
        setEventImage(response.event.file)
        setEventDate(response.event.date.substring(0, 10))
        setEventHour(response.event.hour)
        setBorough(response.event.local.borough)
        setEventComplement(response.event.local.complement)
        setEventCountry(response.event.local.country)
        setEventNumber(response.event.local.number)
        setEventCity(response.event.local.city)
        setEventState(response.event.local.state)
        setEventStreet(response.event.local.street)
        setEventZipCode(response.event.local.zipCode)
        setIsLoading(false)
        setEventResponse(response.event)
    }

    useEffect(() => {
        if(id){
            Testando()
        }
    }, [])

    useEffect(() => {
        async function getStateAndCitties() {
            if (location && eventResponse && !already) {
                console.log('aaaa', location)
                const state = await findState(eventResponse)
                await getCities(state).then((cities: any) => {
                    setLocation(prevState => ({
                        ...prevState,
                        cities: cities
                    }))
                })
                setAlready(true)
            }
        }
        getStateAndCitties()
    }, [eventResponse, location])

    return (
        <Container
            title='Detalhes do Evento'
            hasBtns
            primaryButton={{
                label: id ? isEditable ? 'Confirmar' : 'Editar' : 'Criar',
                onClick: () => {
                    if(id) {
                        if (isEditable) {
                            updateEvent().then((res) => {
                                if(res) {
                                    setOpenModal(true)
                                }
                            })
                        } else {
                            setIsEditable(true)
                        }
                    } else {
                        updateEvent().then((res) => {
                            console.log(res)
                            if(res) {
                                setOpenModal(true)
                            }
                        })
                    }
                },
                disabled: false,
                icon: isEditable && id ? <AiOutlineCheck title="Confirmar" /> : <FaPen title="Editar" />
            }}
            secondaryButton={{
                label: id ? isEditable ? 'Cancelar' : 'Voltar' : 'Voltar',
                onClick: () => {
                    if(id){
                        if (isEditable) {
                            setIsEditable(false)
                        } else {
                            history('/eventos')
                        }
                    }else {
                        history('/eventos')
                    }
                },
                disabled: false,
                icon: !isEditable || !id && <FaChevronLeft title="Voltar" />
            }}
        //subTitle='Onde seus usuários estão atuando'
        >
            <>
                <S.InputsTopContainer>
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Nome do evento"
                        label='Nome do Evento'
                        value={nome}
                        onChange={(e: any) => setNome(e.target.value)}
                        width={'22rem'}
                    />

                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Data do evento"
                        label='Data'
                        value={eventDate}
                        type='date'
                        onChange={(e: any) => setEventDate(e.target.value)}
                        width={'9rem'}
                    />

                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Horário do evento"
                        label='Horário'
                        type='time'
                        value={eventHour}
                        onChange={(e: any) => setEventHour(e.target.value)}
                        width={'9rem'}
                    />

                    <S.ContainerButton>
                        <S.SpanTextPhotoEvent>Foto do evento</S.SpanTextPhotoEvent>

                        <MyDropzoneHomolog
                            disabled={!isEditable}
                            onSendFileToApi={setEventImage}
                        >
                            <S.ButtonSearch
                                onClick={() => filePickerRef.current.click()}
                            >
                                <IoSearch />
                                Procurar
                            </S.ButtonSearch>

                            <S.InputImgEvent
                                disabled={true}
                                // label='Imagem do Evento'
                                value={eventImage ? eventImage?.name || eventImage : 'Ou arrate seu arquivo aqui'}
                                // value={filterInfo.name}
                                // onChange={(e: any) => setFilterInfo(produce(filterInfo, draft => {
                                //     draft.name = e.target.value
                                // }))}
                                width={'14rem'}
                            />
                        </MyDropzoneHomolog>
                    </S.ContainerButton>
                </S.InputsTopContainer>

                <S.InputsMidContainer>
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="CEP do evento"
                        label='CEP'
                        value={eventZipCode}
                        onChange={(e: any) => setEventZipCode(e.target.value)}
                        width={'9.375rem'}
                    />
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Logradouro do evento"
                        label='Logradouro'
                        value={eventStreet}
                        onChange={(e: any) => setEventStreet(e.target.value)}
                        width={'11rem'}
                    />
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Nº"
                        label='Número'
                        type="number"
                        value={eventNumber}
                        onChange={(e: any) => setEventNumber(e.target.value)}
                        width={'4.5rem'}
                    />
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Bairro do evento"
                        label='Bairro'
                        value={borough}
                        onChange={(e: any) => setBorough(e.target.value)}
                        width={'10.5rem'}
                    />
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="País do evento"
                        label='País'
                        value={eventCountry}
                        onChange={(e: any) => setEventCountry(e.target.value)}
                        width={'10.5rem'}
                    />
                    <SelectLabel
                        disabled={!isEditable}
                        isSmall
                        label='UF'
                        optionsData={location.states}
                        state={eventState}
                        onChange={(e: any) => handleGetCities(e.target.value)}
                        width={'4.5rem'}
                        style={{
                            height: '100%'
                        }}
                    />

                    <SelectNome
                        disabled={!isEditable || eventState == ''}
                        isSmall
                        id="city"
                        label='Cidade'
                        optionsData={location.cities}
                        state={eventCity}
                        onChange={(e: any) => setEventCity(e.target.value)}
                        width={'10.5rem'}
                        style={{
                            height: '100%'
                        }}
                    />
                </S.InputsMidContainer>

                <S.InputsBottomContainer>
                    <Input
                        disabled={!isEditable}
                        customPadding={true}
                        isSmall
                        placeholder="Complemento do endereço do evento"
                        label='Complemento do Endereço'
                        value={eventComplement}
                        onChange={(e: any) => setEventComplement(e.target.value)}
                        width={'33rem'}
                    />
                </S.InputsBottomContainer>
            </>
            {openModal && (
                <Modal
                    icon="check"
                    container={`O evento ${nome} foi atualizado com sucesso.`}
                    title="Deu tudo certo!"
                    state={openModal}
                    setState={setOpenModal}
                    onClick={() => history('/eventos')}
                />
            )}
        </Container>
    )
}