// import React, { useState, useEffect, useCallback } from "react";
// import { Table } from "components/Table";
// import api from "services/api";
// import { useNavigate } from "react-router-dom";

// export function Administration() {
//     const router = useNavigate();

//     const [users, setUsers] = useState<any[]>([]);
//     const [count, setCount] = useState(5);
//     const [pagination, setPagination] = useState({
//         page: 1,
//         limit: 5,
//     });

//     const fetchUsers = useCallback(async () => {
//         try {
//             const { data } = await api.get(
//                 `/users?role=ADMIN&limit=${pagination.limit}&page=${pagination.page}`
//             );
//             console.log(data);
//             setUsers(data.users);
//             setCount(data.count);
//         } catch (err) {
//             console.error(
//                 "Ocorreu um erro ao listar os usuários administradores"
//             );
//         }
//     }, []);

//     const handleChangePage = (
//         event: React.MouseEvent<HTMLButtonElement> | null,
//         newPage: number
//     ) => {
//         setPagination((prevState) => ({
//             ...prevState,
//             page: newPage + 1,
//         }));
//         fetchUsers();
//     };

//     const handleChangeRowsPerPage = (
//         event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//     ) => {
//         setPagination({
//             page: 1,
//             limit: parseInt(event.target.value, 10),
//         });
//         fetchUsers();
//     };

//     useEffect(() => {
//         const isMounted = true;
//         if (!isMounted) return void fetchUsers();
//     }, [fetchUsers]);

//     return (
//         <Table
//             title={"Administração Struko"}
//             subtitle={"Consulte os administradores da plataforma"}
//             columns={[
//                 {
//                     field: "fullName",
//                     title: "Nome",
//                 },
//                 {
//                     field: "email",
//                     title: "Email",
//                 },
//                 {
//                     field: "",
//                     title: "",
//                     width: 1000,
//                 },
//             ]}
//             data={users}
//             primaryButton={{
//                 title: "Cadastrar adminitrador",
//                 onClick: () => router("/cadastrar-adm"),
//             }}
//             withoutFilter
//             TablePaginationActionsProps={{
//                 page: pagination.page - 1,
//                 count: count,
//                 rowsPerPage: pagination.limit,
//                 handleChangeRowsPerPage: handleChangeRowsPerPage,
//                 onPageChange: handleChangePage,
//             }}
//         />
//     );
// }
import React, { useState, useEffect, useCallback } from "react";
import { Table } from "components/Table";
import api from "services/api";
import { useNavigate } from "react-router-dom";
import { IconButton } from "components/IconButton";
import { Status } from "components/Status";
import { UsersResponseData } from "pages/Users/typings";
import { IPropsDialogModal } from "components/DialogModal";
import { useDialog } from "hooks/dialog";
import { HiOutlineCheck, HiOutlineX } from "react-icons/hi";

export function Administration() {
    const router = useNavigate();
    const navigate = useNavigate();

    const [users, setUsers] = useState<any[]>([]);
    const [count, setCount] = useState(0); // Initialize count as 0
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 5,
    });

    const fetchUsers = useCallback(async () => {
        try {
            const { data } = await api.get(
                `/users?role=ADMIN&limit=${pagination.limit}&page=${pagination.page}`
            );
            console.log(data);
            setUsers(data.users);
            setCount(data.count);
        } catch (err) {
            console.error(
                "Ocorreu um erro ao listar os usuários administradores"
            );
        }
    }, [pagination.limit, pagination.page]); // Add dependencies to the useCallback hook

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPagination((prevState) => ({
            ...prevState,
            page: newPage + 1,
        }));
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPagination({
            page: 1,
            limit: parseInt(event.target.value, 10),
        });
    };

    useEffect(() => {
        let isMounted = true; // Initialize isMounted variable

        // Use cleanup function to handle component unmount
        return () => {
            isMounted = false;
        };
    }, []); // Empty dependency array to run only once on mount

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]); // Add fetchUsers as a dependency

    useEffect(() => {
        fetchUsers();
    }, [pagination]); // Add pagination as a dependency

    const [isOpenModal, setIsOpenModal] = useState(false);

    // modal config
    const { setInfoModal, closeDialog } = useDialog();

    const handleSwitchStatusDialog = (element: UsersResponseData) => {
        console.log(element);
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                type: `${element.status == 1 ? "error" : "success"}`,
                title: {
                    text: `${
                        element.status == 1 ? "Excluir" : "Ativar"
                    } Administrador`,
                    icon:
                        element.status == 1 ? (
                            <HiOutlineX size={28} />
                        ) : (
                            <HiOutlineCheck size={28} />
                        ),
                },
                description: `Deseja realmente ${
                    element.status == 1 ? "Excluir" : "Ativar"
                } o Administrador?`,
                secondarybutton: {
                    text: "Cancelar",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "dialog-cancelar",
                },
                primarybutton: {
                    text: `${element.status == 1 ? "Excluir" : "Ativar"}`,
                    onClick: () => {
                        deleteUsers(element);
                    },
                    id: "dialog-switch",
                    type: `${element.status == 1 ? "error" : "success"}`,
                },
            },
        };
        setInfoModal(info);
    };

    const deleteUsers = async (element: UsersResponseData) => {
        try {
            await api.delete(`/users/${element.id}`);

            successDialog(element);
        } catch (error) {
            errorDialog(element);
            console.log(error);
        } finally {
            fetchUsers();
        }
    };

    const successDialog = (element: UsersResponseData) => {
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                title: {
                    text: `O Administrador foi ${
                        element.status == 1 ? "excluido" : "ativado"
                    } com sucesso!`,
                    icon: <HiOutlineCheck size={28} />,
                },
                description: `O Administrador ${element.fullName} foi ${
                    element.status == 1 ? "excluido" : "ativado"
                } com sucesso na plataforma`,
                primarybutton: {
                    text: "Ok",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "ok",
                    type: "success",
                },
            },
        };
        setInfoModal(info);
    };

    const errorDialog = (element: UsersResponseData) => {
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                type: "error",
                title: {
                    text: `Falha ao ${
                        element.status == 1 ? "inativar" : "ativar"
                    }!`,
                    icon: <HiOutlineX size={28} />,
                },
                description: `Houve um erro ao ${
                    element.status == 1 ? "inativar" : "ativar"
                } o consultor ${
                    element.fullName
                } na plataforma, contate o administrador.`,
                primarybutton: {
                    text: "Ok",
                    type: "error",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "ok",
                },
            },
        };
        setInfoModal(info);
    };

    return (
        <>
            <Table
                title={"Administração Struko"}
                subtitle={"Consulte os administradores da plataforma"}
                columns={[
                    {
                        field: "fullName",
                        title: "Nome",
                    },
                    {
                        field: "email",
                        title: "Email",
                    },
                    {
                        field: "",
                        title: "",
                        width: 1000,
                    },
                    {
                        field: "Visualizar",
                        title: "",
                        onRenderContent: (data) => (
                            <IconButton
                                icon="visibility"
                                onClick={() => {
                                    navigate(`/editar-adm/${data.id}`);
                                }}
                            />
                        ),
                    },
                    {
                        field: "Deletar",
                        title: "",
                        onRenderContent: (data) => (
                            <IconButton
                                icon="delete"
                                onClick={() => {
                                    handleSwitchStatusDialog(data);
                                }}
                            />
                        ),
                    },
                ]}
                data={users}
                primaryButton={{
                    title: "Cadastrar adminitrador",
                    onClick: () => router("/cadastrar-adm"),
                }}
                withoutFilter
                TablePaginationActionsProps={{
                    page: pagination.page - 1,
                    count: count,
                    rowsPerPage: pagination.limit,
                    handleChangeRowsPerPage: handleChangeRowsPerPage,
                    onPageChange: handleChangePage,
                }}
            />
        </>
    );
}
