import React from 'react'
import * as S from './styles'
import { ModalProps } from './typings'

import { Title } from 'components/Text'
import { Button } from 'components/Button'
import MaterialIcon from '@material/react-material-icon'
import MUIModal from '@mui/material/Modal'


export function Modal({
  icon,
  title,
  subTitle,
  container,
  state,
  setState,
  onClick,
  customIcon
}: ModalProps) {

  return (
    <MUIModal
      open={state}
      onClose={() => setState(false)}
      aria-labelledby={title}
      aria-describedby={subTitle}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <S.Modal>
        <S.Icon>
          {customIcon ? customIcon : <MaterialIcon icon={icon} />}
        </S.Icon>
        <S.Titles>
          <Title isBold>{title}</Title>
          <Title style={{
            marginTop: '-0.6rem'
          }}>{subTitle}</Title>
        </S.Titles>
        <div>
          {container}
        </div>

        <S.Buttons>
          <Button
            title='Voltar'
            onClick={() => setState(false)}
            isWhite
            width={'7.75rem'}
            height={'2.5rem'}
          />
          {onClick && (
            <Button
              title='Confirmar'
              onClick={onClick}
              width={'7.75rem'}
              height={'2.5rem'}
            />
          )}
        </S.Buttons>
      </S.Modal>
    </MUIModal>
  )
}