import React from 'react'
import * as S from './styles'
import { StatusProps } from './typings'

export function Status({ isDisabled, title }: StatusProps) {
  return (
    <S.Status isDisabled={isDisabled}>
      <span>{title}</span>
    </S.Status>
  )
}