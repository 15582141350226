import styled, { keyframes } from 'styled-components';

export const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
	display: inline-block;
	width: 72px;
	height: 72px;
	border: 5px solid #fff;
	border-top-color: ${({ theme }) => theme.primary};
	border-radius: 50%;
	animation: ${rotate} 1s ease-in-out infinite;
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
`;
