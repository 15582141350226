import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableColumnModel } from "components/Table/typings";
import { ColumnsMethods, UsersResponseData } from "./typings";
import { Status } from "components/Status";
import { IconButton } from "components/IconButton";
import { SquareAvatar } from "components/Avatar";

import noAvatarIcon from "assets/icons/avatar.svg";
import api from "services/api";
import { AiOutlinePoweroff } from "react-icons/ai";

export function GetColumns(columnsMethods: ColumnsMethods) {
    const navigate = useNavigate();

    const columns: TableColumnModel[] = [
        {
            field: "fullName",
            title: "NOME",
            onRenderContent: (data: UsersResponseData) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        gap: "1.5625rem",
                    }}
                >
                    <SquareAvatar
                        alt={`Imagem de ${data.fullName}`}
                        src={data.picture ? `${data.file}` : noAvatarIcon}
                    />
                    <p
                        style={{
                            marginRight: "20px",
                        }}
                    >
                        {data.fullName}
                    </p>
                </div>
            ),
        },

        {
            field: "country",
            title: "PAÍS",
        },
        {
            field: "city",
            title: "CIDADE",
        },
        {
            field: "state",
            title: "ESTADO",
        },
        {
            field: "sex",
            title: "SEXO",
            onRenderContent: (data: UsersResponseData) => (
                <span>{data.sex === "F" ? "Feminino" : "Masculino"}</span>
            ),
        },
        {
            field: "",
            title: "STATUS",
            onRenderContent: (data: UsersResponseData) => (
                <div
                    className={`py-[5px] text-xs ${
                        data.status == 1
                            ? "text-green-600 bg-green-100"
                            : "text-red-600 bg-red-100"
                    }  rounded-md px-4`}
                >
                    {data.status == 1 ? "Ativo" : "Inativo"}
                </div>
            ),
        },
        {
            field: "",
            title: "PLANO",
            onRenderContent: (data: UsersResponseData) => (
                <Status
                    isDisabled={data.typeSubs === "Gratuito"}
                    title={data.typeSubs}
                />
            ),
        },
        {
            field: "Editar",
            title: "",
            onRenderContent: (data) =>
                data.typeSubs !== "Premium" && (
                    <IconButton
                        icon="edit"
                        onClick={() =>
                            columnsMethods.edit(
                                data.id,
                                data.subscription === null,
                                data.typeSubs
                            )
                        }
                    />
                ),
        },
        {
            field: "Visualizar",
            title: "",
            onRenderContent: (data: UsersResponseData) => (
                <IconButton
                    icon="visibility"
                    onClick={() => {
                        navigate(`/usuarios/${data.id}`);
                    }}
                />
            ),
        },
        {
            field: "delete",
            title: "",
            onRenderContent: (data: UsersResponseData) => (
                <IconButton
                    icon="power_settings_new"
                    onClick={() => {
                        columnsMethods.delete(data);
                    }}
                />
            ),
        },
    ];

    return columns;
}
