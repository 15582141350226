import React from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import Routes from "./routes";
import { AuthProvider } from "hooks/useAuth";
import { GlobalStyle } from "./styles/globals";
import "@material/react-material-icon/dist/material-icon.css";
import { DialogProvider } from "context/useContext";
import { AlertProvider } from "context/useAlert";
import { DialogModal } from "components/DialogModal";
import { DialogV2 } from "hooks/dialog";
import "./styles/plugin.css";

function App() {
    return (
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <DialogProvider>
                    <AlertProvider>
                        <DialogV2>
                            <DialogModal />
                            <GlobalStyle />

                            <Routes />
                        </DialogV2>
                    </AlertProvider>
                </DialogProvider>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;
