import styled from 'styled-components';
import { InputProps } from './typings';
import { font } from 'utils/fonts';
import { Label } from 'components/Label';

export const InputContainer = styled.div`
	flex: ${({ flex }) => (flex ? flex : 1)};
	width: ${(props) => (props.containerWidth ? props.containerWidth : '100%')};
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: '2rem';
	margin-left: ${(props: InputProps) => props.marginLeft};
	margin-right: ${(props: InputProps) => props.marginRight};
`;

export const InputContent = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

export const Input = styled.input<InputProps>`
	background: ${(props) => props.theme.background};
	width: ${(props) => (props.width ? props.width : '100%')};
	height: ${(props) => (props.height ? props.height : '40px')};
	border-radius: 0.25rem;
	border: none;
	outline-color: ${(props) =>
		props.hasError ? props.theme.error : props.theme.primary};
	padding: 0 1rem 0 1rem;
	padding-right: ${(p) => p.isPassword && '3.1rem'};
	margin-left: ${(props: InputProps) => props.marginLeft};
`;

export const ShowPassContainer = styled.div`
	position: absolute;
	margin-right: 1rem;
`;

export const StyledLabel = styled(Label)<InputProps>`
	margin-left: 8px;
	font-size: ${(props) => (props.isSmall ? font.size14 : undefined)};
	color: ${(props) =>
		props.isSmall && !props.color
			? '#767676'
			: !!props.color
			? props.color
			: props.theme.subtle};
`;
