import styled from "styled-components";

export const ContainerIcon = styled.span`
  color: #ffbd12;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonEdit = styled.button`
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  border: none;
  color: #ffbd12;
`;
