export function getStates() {
  return getPromiseFetch(
    "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
  );
}

export function getCities(stateId: number) {
  return getPromiseFetch(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/distritos?orderBy=nome`
  );
}

function getPromiseFetch(url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        console.error("err", err);
        reject(err);
      });
  });
}
