import styled, { css } from 'styled-components';

export const Header = styled.header`
	width: 100%;
`;

export const StyledContainer = styled.div`
	${({ theme }) => css`
		display: flex;
		justify-content: space-between;
		align-content: center;
		flex-wrap: wrap;
		border-bottom: 1px solid ${(props) => props.theme.outline};
		padding-bottom: 32px;
		& div {
			display: flex;
		}
	`}
`;

export const StyledIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 30px;
	${({ theme }) => css`
		& svg {
			color: ${theme.primary};
		}
	`}
`;

export const StyledTitle = styled.h1`
	font-weight: 700;
	font-size: 32px;
	margin-left: 25px;
	color: ${(props) => props.theme.header};
`;

export const ContainerButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 30px;
	column-gap: 16px;
`;
