import cogoToast from "cogo-toast";
import api from "services/api";

export async function handleRegisterEvent(body: any) {
    try {
        await api.post("/events", body);
        return true;
    } catch (error) {
        cogoToast.error(
            "Houve um erro ao cadastrar evento. Por favor reveja os campos e tente novamente."
        );
        return false;
    }
}

export async function handleEditEvent(id: any, body: any) {
    try {
        await api.put(`/events/${id}`, body);
        return true;
    } catch (error) {
        cogoToast.error(
            "Houve um erro ao editar evento. Por favor reveja os campos e tente novamente."
        );
    }
}

export async function getEvents(page: number, limit: number) {
    try {
        const { data } = await api.get(`/events?page=${page}&limit=${limit}`);
        console.log(data);
        return {
            events: data.events,
            count: data.count,
        };
    } catch (error) {
        cogoToast.error("Houve um erro ao visualizar eventos");
        return null;
    }
}

export async function getEvent(id: any) {
    try {
        const { data } = await api.get(`/events/${id}`);
        console.log(data);
        return data;
    } catch (error) {
        cogoToast.error("Houve ao visualizar evento");
        return null;
    }
}

export async function toggleEventStatus(id: any) {
    try {
        await api.patch(`/events/${id}`);
        return true;
    } catch (error) {
        cogoToast.error("Houve ao mudar status do evento");
    }
}
