import React from "react";
import * as S from "./styles";

import { Button as MUIButton, CircularProgress } from "@mui/material";
import { ButtonProps } from "./typings";
import MaterialIcon from "@material/react-material-icon";

export function Button({
    title,
    isLoading,
    width,
    height,
    marginTop,
    marginBottom,
    iconName,
    isSmall,
    onClick,
    isWhite,
    ...props
}: ButtonProps) {
    return (
        <S.ButtonContainer
            width={width}
            height={height}
            marginTop={marginTop}
            marginBottom={marginBottom}
            isSmall={isSmall}
            isWhite={isWhite}
        >
            <MUIButton variant="contained" size="medium" onClick={onClick}>
                {isLoading ? (
                    <CircularProgress
                        style={{
                            width: "1.2rem",
                            height: "1.2rem",
                        }}
                    />
                ) : (
                    <>
                        {iconName && (
                            <MaterialIcon icon={iconName} className="icon" />
                        )}
                        {title ? title : ""}
                    </>
                )}
            </MUIButton>
        </S.ButtonContainer>
    );
}
