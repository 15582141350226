import styled from 'styled-components';
import { font } from 'utils/fonts';
import { LabelProps } from './typings';

export const Label = styled.label<LabelProps>`
	font-size: ${(props) => (props.fontSize ? props.fontSize : font.size16)};
	color: ${(props) =>
		props.fontColor ? props.fontColor : (props) => props.theme.text};
	font-weight: ${(props) =>
		props.fontWeight ? props.fontWeight : font.weightLight};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
`;
