import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

interface Props2 {
  onSendFileToApi?: any
  children: any
  preview?: any
  cancelRemove?: any
  disabled?: boolean
}

export const MyDropzoneFiles: React.FC<Props2> = ({
  onSendFileToApi,
  children,
  preview,
  cancelRemove
}) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0]
      const uri = URL.createObjectURL(file)

      onSendFileToApi(file)
      if(preview){
        preview(uri)
        cancelRemove(false)
      }
    },
    [onSendFileToApi, preview]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/x-freearc': ['.jpg', '.jpeg', '.png']
    }
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <p>{children}</p> : <p>{children}</p>}
    </div>
  )
}

export const MyDropzoneHomolog: React.FC<Props2> = ({
  onSendFileToApi,
  children,
  disabled = false
}) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0]

      console.log(file)

      onSendFileToApi(file)
    },
    [onSendFileToApi]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/x-freearc': ['.jpg', '.jpeg', '.png']
    }
  })

  return (
    <div {...getRootProps()}>
      <input disabled={disabled} {...getInputProps()} />
      {isDragActive ? <p style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
      }}>{children}</p> : <p style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
      }}>{children}</p>}
    </div>
  )
}