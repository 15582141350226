export const font = {
  size11: '0.6875rem',
  size12: '0.75rem',
  size14: '0.875rem',
  size16: '1rem',
  size20: '1.25rem',
  size24: '1.5rem',

  weightLight: 400,
  weigthNormal: 500,
  weightStrong: 700
}
