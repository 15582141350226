export const theme = {
    primary: "#FFBD12",
    primaryHover: "#ffac12",
    header: "#344767",
    error: "#D65050",
    text: "#111111",
    subtle: "#767676",
    surface: "#FFFFFF",
    sitemap: "#AAAAAA",
    background: "#EEF0F8",
    bg: "#FDF7DA",
    outline: "#D0D0D0",
    disabledText: "#B1B4C1",
    textInput: "#5E6278",
    noInput: "#A1A5B7",
    contract: "#B7C8EC",
    subtitle: "#B5B5C3",
    gray: "#C4C4C4",
    locked: "#E3E5EE",
    border: "#E5EAEE",
    input: "#171717",
    unselected: "#94A3B8",
    borderCard: "#bdbdbd",
    textCard: "#5A5A5A",
    no_input: "#A1A5B7",
    separator: "#F3F3F3",
    filterMonth: "#788395",
    filterColor:
        "invert(55%) sepia(81%) saturate(3888%) hue-rotate(173deg) brightness(91%) contrast(103%)",
};
