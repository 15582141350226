import { Alert, AlertTitle } from '@mui/material'
import React, { useState, useEffect } from 'react'
import * as Styles from './styles'

export interface AlertProps {
  className?: string
  severety: 'error' | 'warning' | 'success' | 'info'
  title: string
  description?: React.ReactNode
}

export const AlertComponent = (props: AlertProps) => {

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const show = setTimeout(() => setVisible(e => true), 10)
    const hidden = setTimeout(() => setVisible(e => false), 4600)

    return () => {
      clearTimeout(show)
      clearTimeout(hidden)
    }
  }, [])

  return (
    <Styles.Container className={`${props.className && props.className} ${visible && 'visible'}`} >
      <Alert severity={props.severety}>
        {props.description ? (
          <>
            <AlertTitle>{props.title}</AlertTitle>
            {props.description}
          </>
        ) : props.title}
      </Alert>
    </Styles.Container>
  )
}