import React, { useEffect, useState } from "react";

import { Table } from "components/Table";
import * as S from "./styles";

import { IconButton } from "components/IconButton";
import api from "services/api";
import { useNavigate } from "react-router-dom";

export interface filterInfoProps {
    name?: string;
    location?: string;
    sex?: string;
    city?: string;
    subscription?: string;
    initialDate?: string;
    finalDate?: string;
    role?: string;
    page?: number;
    limit?: number;
    count?: number;
}

interface IUserPerState {
    state: string;
    amountUsers: number;
}

export function StateTables() {
    const navigate = useNavigate();

    const [users, setUsers] = useState<IUserPerState[]>([]);

    const columns = [
        {
            field: "state",
            title: "Estado",
            onRenderContent: (data: any) => data?.state,
        },
        {
            field: "user",
            title: "Usuarios",
            onRenderContent: (data: any) => data?.amountUsers,
        },
        {
            field: "Visualizar",
            title: "",
            onRenderContent: (data) => (
                <IconButton
                    icon="visibility"
                    onClick={() => {
                        console.log(data);
                        navigate("/usuarios", {
                            state: {
                                state: data.acronym,
                            },
                        });
                    }}
                />
            ),
        },
    ];

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [count, setCount] = useState(1);

    const getUserPerState = async (limit: number, page: number) => {
        const { data } = await api.get(
            `/dashboard/clientsPerState?limit=${limit}&page=${page}`
        );
        console.log(data.states);
        setUsers(data.states);
        setCount(data.count);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage + 1);
        // getUserPerState(limit, newPage + 1);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setPage(1);
        setLimit(parseInt(event.target.value));

        // getUserPerState(parseInt(event.target.value), 1);
    };

    useEffect(() => {
        getUserPerState(limit, page);
    }, [limit, page, count]);

    return (
        <S.ContainerTable>
            <Table
                title={"Usuário por estado"}
                subtitle={"Visualize os usuários por estado"}
                columns={columns}
                TablePaginationActionsProps={{
                    page: page - 1,
                    count: count,
                    rowsPerPage: limit,
                    handleChangeRowsPerPage: handleChangeRowsPerPage,
                    onPageChange: handleChangePage,
                }}
                data={users}
                withoutFilter
            />
        </S.ContainerTable>
    );
}
