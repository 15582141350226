import React from "react";
import * as S from "./styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DataProps, RadioButtonsGroupProps } from "./typings";

export function RadioButtonsGroup({
    data,
    defaultValue,
    onChange,
}: RadioButtonsGroupProps) {
    return (
        <S.RadioButtonsGroup>
            <RadioGroup
                defaultValue={defaultValue || data[0].value}
                name="radio-buttons-group"
                onChange={onChange}
            >
                {data.map((item: DataProps, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                    />
                ))}
            </RadioGroup>
        </S.RadioButtonsGroup>
    );
}
