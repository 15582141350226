import styled from 'styled-components';
import { SelectProps } from './typings';

export const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 32px;
`;

export const ContainerTable = styled.div`
	margin-top: 25px;

	@media screen and (max-width: 700px) {
		width: 90%;
	}
`;

export const RowTable = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	& div div {
		margin: 8px;
	}

	@media screen and (max-width: 700px) {
		display: flex;
		flex-direction: column;
		width: 100%;

		& div {
			width: 100%;
		}
	}
`;

export const ContainerTraining = styled.section`
	width: 100%;
	justify-content: flex-start;
	align-items: center;
	padding: 32px;

	flex-wrap: nowrap;
	gap: 16px;

	display: flex;
	max-width: 80vw;
	overflow-x: auto;
	text-overflow: clip;

	scrollbar-width: auto;
	scrollbar-color: ${({ theme }) => theme.primary} #ffffff;
	&::-webkit-scrollbar {
		width: 12px;
	}
	&::-webkit-scrollbar-track {
		background: #ffffff;
	}
	&::-webkit-scrollbar-thumb {
		background-color: ${({ theme }) => theme.primary};
		border-radius: 12px;
		border: 4px solid #ffffff;
	}

	@media screen and (max-width: 1438px) {
		max-width: 75vw;
	}

	@media screen and (max-width: 1180px) {
		max-width: 90vw;
	}
`;

export const Text = styled.p`
	font-size: 20px;
	line-height: 26px;
	color: ${({ theme }) => theme.header};
`;

export const ContainerSelect = styled.div<SelectProps>`
	flex: 1;
	* {
		margin: 0px !important;
	}

	label {
		margin-left: 8px !important;
		color: #767676;
	}

	& div div select {
		margin: 8px !important;
		height: 40px;
	}
`;

export const MainContainerSkeleton = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	background-color: #fff;
	padding: 2rem 1rem;
	border-radius: 0.75rem;
`;

export const SkeletonRow = styled.div`
	display: flex;
	flex-direction: row;
	row-gap: 20px;
	column-gap: 25px;
	overflow-x: hidden;
	justify-content: space-between;
`;

export const ContainerSkeleton = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	margin-top: 7vh;
`;
