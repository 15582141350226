import styled from 'styled-components'
import { font } from 'utils/fonts'

export const RadioButtonsGroup = styled.div`
  color: ${props => props.theme.textInput};
  font-size: ${font.size14};
  font-family: 'Poppins', sans-serif;

  .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: ${props => props.theme.primary};
  }
`
