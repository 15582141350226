import styled, { css } from 'styled-components'

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        height: 500px;
        display: flex;
        flex-direction: column;

        .mapMarker{
            font-weight: bolder;
            color: ${theme.primary}!important;
            font-size: 1.5rem;
        }

        .info-window{
            color: ${theme.primary}!important;
            
            h2 {
                font-size: 1.25rem;
                line-height: 1.275rem;
                font-weight: 700;

                strong {
                    font-weight: 900;
                    text-decoration: underline;
                }
            }

            h6 {
                font-size: 1.175rem;
                font-weight: 900;
                color: black;
            }
        }
    `}
`

export const UserList = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 2rem;
    max-height: 85vh;
    overflow-y: auto;
    text-overflow: clip;
    margin-top: 1.75rem;
    padding: 0 3rem;

    .item {
        min-width: 180px;
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1rem;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 4px;
        }
    }
`