import React from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import * as S from './styles'
import { ContainerProps } from './typings'

export function Container({ title, subTitle, children, hasBtns, primaryButton, secondaryButton, hasIconBack, returnOnClick }: ContainerProps) {
  return (
    <S.Container>
      <S.Header>
        <S.LeftContent>
          {hasIconBack && (
            <FaChevronLeft title="Voltar" onClick={returnOnClick} className='return' size={30}/>
          )}
          <S.Titles>
            <h3 className="title">{title}</h3>
            <span className="subTitle">{subTitle}</span>
          </S.Titles>
        </S.LeftContent>
        {hasBtns && (
          <div className="btnsContainer">
            {secondaryButton && (
              <button
                className='secondaryButton'
                onClick={secondaryButton.onClick}
                aria-label={secondaryButton.label}
              >
                {secondaryButton?.icon}
                {secondaryButton.label}
              </button>
            )}
            {primaryButton && (
              <button
                className='primaryButton'
                onClick={primaryButton.onClick}
                aria-label={primaryButton.label}
              >
                {primaryButton?.icon}
                {primaryButton.label}
              </button>
            )}
          </div>
        )}
      </S.Header>
      <S.Children>
        {children}
      </S.Children>
    </S.Container>
  )
}