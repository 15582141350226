import React, { useEffect, useMemo, useRef, useState } from "react";

import {
    Container,
    DataContainer,
    SubTitle,
    Table as TableStyle,
    TableBody,
    TableHead,
    Td,
    Th,
    Title,
    Tr,
} from "./styles";
import TablePagination from "@mui/material/TablePagination";
import { TableProps } from "./typings";
import { Button } from "components/Button";
import { Filter } from "components/Filter";
import { FilterProps } from "components/Filter/typings";
import { Popover, Skeleton } from "@mui/material";
import { loadingColumns, loadingData } from "./data";
import { Modal } from "components/Modal";

export function Table({
    columns,
    data,
    title,
    subtitle,
    hasThreeOptions,
    isLoading,
    onRowClick,
    TablePaginationActionsProps,
    withoutPagination,
    topButtonsRef,
    withoutButtons,
    withoutFilter,
    filterProps,
    primaryButton,
}: TableProps) {
    let tableData = isLoading ? loadingData : data;
    let columnsTable = isLoading ? (loadingColumns as any) : columns;

    const filterRef = useRef();

    const [isOpenFilter, setIsOpenFilter] = useState<HTMLButtonElement | null>(
        null
    );

    const open = Boolean(isOpenFilter);
    const id = open ? "simple-popover" : undefined;

    function handleClickFilter(event: React.MouseEvent<HTMLButtonElement>) {
        setIsOpenFilter(event.currentTarget);
    }

    function handleCloseFilter() {
        setIsOpenFilter(null);
    }

    const [collapse, setCollapse] = useState(false);

    const handleTableHeaders = useMemo(
        () =>
            tableData &&
            tableData.length > 0 && (
                <TableHead data-testid={"table-head"}>
                    {isLoading ? (
                        <Skeleton animation="wave">
                            <Tr data-testid={"table-header-row"}>
                                {columnsTable.map(
                                    (column: any, index: number) => (
                                        <Th
                                            data-testid={`table-header-cell-${index}`}
                                            width={column.width}
                                            key={index}
                                            isHidden={column.isHidden}
                                        >
                                            {column.customJSX}
                                            {column.title}
                                        </Th>
                                    )
                                )}
                            </Tr>
                        </Skeleton>
                    ) : (
                        <Tr data-testid={"table-header-row"}>
                            {columnsTable.map((column: any, index: number) => (
                                <Th
                                    data-testid={`table-header-cell-${index}`}
                                    width={column.width}
                                    key={index}
                                    isHidden={column.isHidden}
                                >
                                    {column.customJSX}
                                    {column.title}
                                </Th>
                            ))}
                        </Tr>
                    )}
                </TableHead>
            ),
        [columnsTable, tableData]
    );
    const handleTd = useMemo(
        () =>
            Array.isArray(tableData) && (
                <TableBody>
                    {tableData.map((tableData, index: number) => (
                        <React.Fragment key={index}>
                            {isLoading ? (
                                <Skeleton animation="wave">
                                    <Tr>
                                        {tableData.length > 0
                                            ? tableData.slice(
                                                  TablePaginationActionsProps?.page *
                                                      TablePaginationActionsProps?.rowsPerPage,
                                                  TablePaginationActionsProps?.page *
                                                      TablePaginationActionsProps?.rowsPerPage +
                                                      TablePaginationActionsProps?.rowsPerPage
                                              )
                                            : columnsTable.map(
                                                  (
                                                      column: any,
                                                      index: number
                                                  ) => (
                                                      <Td
                                                          style={{
                                                              width: hasThreeOptions
                                                                  ? "50%"
                                                                  : "",
                                                          }}
                                                          isHidden={
                                                              column.isHidden
                                                          }
                                                          onClick={() =>
                                                              !column?.notClickable &&
                                                              onRowClick?.(
                                                                  tableData
                                                              )
                                                          }
                                                          clickable={
                                                              !!onRowClick &&
                                                              !column.notClickable
                                                          }
                                                          key={index}
                                                      >
                                                          {column.formatterFunction
                                                              ? column.formatterFunction(
                                                                    tableData[
                                                                        column
                                                                            .field
                                                                    ]
                                                                )
                                                              : (column?.onRenderContent && (
                                                                    <DataContainer>
                                                                        {column?.onRenderContent?.(
                                                                            tableData
                                                                        )}
                                                                    </DataContainer>
                                                                )) ||
                                                                (column.hasSecondField ? (
                                                                    <div>
                                                                        {tableData[
                                                                            column
                                                                                ?.field
                                                                        ] +
                                                                            " " +
                                                                            tableData[
                                                                                column
                                                                                    ?.secondField
                                                                            ] +
                                                                            " " +
                                                                            (column.hasThirdField
                                                                                ? tableData[
                                                                                      column
                                                                                          ?.thirdField
                                                                                  ]
                                                                                : "")}
                                                                    </div>
                                                                ) : (
                                                                    tableData[
                                                                        column
                                                                            .field
                                                                    ]
                                                                ))}
                                                      </Td>
                                                  )
                                              )}
                                    </Tr>
                                </Skeleton>
                            ) : (
                                <Tr>
                                    {tableData.length > 0
                                        ? tableData.slice(
                                              TablePaginationActionsProps?.page *
                                                  TablePaginationActionsProps?.rowsPerPage,
                                              TablePaginationActionsProps?.page *
                                                  TablePaginationActionsProps?.rowsPerPage +
                                                  TablePaginationActionsProps?.rowsPerPage
                                          )
                                        : columnsTable.map(
                                              (column: any, index: number) => (
                                                  <Td
                                                      style={{
                                                          width: hasThreeOptions
                                                              ? "50%"
                                                              : "",
                                                      }}
                                                      isHidden={column.isHidden}
                                                      onClick={() =>
                                                          !column?.notClickable &&
                                                          onRowClick?.(
                                                              tableData
                                                          )
                                                      }
                                                      clickable={
                                                          !!onRowClick &&
                                                          !column.notClickable
                                                      }
                                                      key={index}
                                                  >
                                                      {column.formatterFunction
                                                          ? column.formatterFunction(
                                                                tableData[
                                                                    column.field
                                                                ]
                                                            )
                                                          : (column?.onRenderContent && (
                                                                <DataContainer>
                                                                    {column?.onRenderContent?.(
                                                                        tableData
                                                                    )}
                                                                </DataContainer>
                                                            )) ||
                                                            (column.hasSecondField ? (
                                                                <div>
                                                                    {tableData[
                                                                        column
                                                                            ?.field
                                                                    ] +
                                                                        " " +
                                                                        tableData[
                                                                            column
                                                                                ?.secondField
                                                                        ] +
                                                                        " " +
                                                                        (column.hasThirdField
                                                                            ? tableData[
                                                                                  column
                                                                                      ?.thirdField
                                                                              ]
                                                                            : "")}
                                                                </div>
                                                            ) : (
                                                                tableData[
                                                                    column.field
                                                                ]
                                                            ))}
                                                  </Td>
                                              )
                                          )}
                                </Tr>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            ),
        [columnsTable, tableData]
    );
    const [isOpenModal, setIsOpenModal] = useState(false);
    return (
        
        <Container>
            <div className="top-container">
                <div className="titles">
                    <Title>{title}</Title>
                    <SubTitle>{subtitle}</SubTitle>
                </div>
                {/* {isLoading && <SpinnerLoaderPrimary />} */}
                {!withoutFilter && (
                    <div className="top-buttons" ref={topButtonsRef}>
                        <Button
                            title="Filtro"
                            iconName="filter_list"
                            isSmall
                            width={"5.875rem"}
                            height={"2rem"}
                            // onClick={handleClickFilter}
                            onClick={() => setCollapse(!collapse)}
                        />
                        {/* <Popover
              id={id}
              open={open}
              anchorEl={isOpenFilter}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <div style={{ width: '250px', height: '350px' }}>
                <Filter
                  {...filterProps as FilterProps}
                />

              </div>

            </Popover> */}

                        {collapse && (
                            <Filter {...(filterProps as FilterProps)} />
                        )}
                    </div>
                )}
                {!withoutButtons && (
                    <div>
                        {primaryButton && (
                            <button
                                onClick={primaryButton.onClick}
                                className="button-style"
                            >
                                {primaryButton.title}
                            </button>
                        )}
                    </div>
                )}
            </div>
            <TableStyle data-testid={"table-container"}>
                {handleTableHeaders}
                {handleTd}
            </TableStyle>
            {withoutPagination ? (
                ""
            ) : (
                <div className="pagination-style">
                    <TablePagination
                        component="div"
                        count={TablePaginationActionsProps?.count}
                        page={TablePaginationActionsProps?.page}
                        onPageChange={TablePaginationActionsProps?.onPageChange}
                        rowsPerPage={TablePaginationActionsProps?.rowsPerPage}
                        onRowsPerPageChange={
                            TablePaginationActionsProps?.handleChangeRowsPerPage
                        }
                        labelRowsPerPage={"Linhas por página:"}
                        labelDisplayedRows={({ from, to, count }) => {
                            return "" + from + "-" + to + " de " + count;
                        }}
                        rowsPerPageOptions={[20, 10, 5]}
                    />
                </div>
            )}
        </Container>
    );
}
