import cogoToast from 'cogo-toast'
import api from 'services/api'

export async function Login(email: string, password: string) {
  try {
    const request = await api.post('/auth/login', {
      email,
      password
    })
    return request
  } catch (error) {
    cogoToast.error('E-mail ou senha inválidos')
    return null
  }
}
