import React from 'react';
import * as S from "./styles"
import { Props } from './types';

export const LoadingModal: React.FC<Props> = ({ show }) => {
	return show ? (
		<S.Overlay>
			<S.Spinner />
		</S.Overlay>
	)
 : null;
};

