import styled from "styled-components";

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: auto;
  height: auto; */
  flex-direction: column;
  background: ${(props) => props.theme.surface};
  box-shadow: 0 0.125rem 1.0625rem rgba(0, 0, 0, 0.06);
  border-radius: 0.625rem;

  position: absolute;
  right: 2%;
  margin-top: 2.75rem;
  width: 639px;
  min-height: 248px;
  z-index: 1;

  padding: 0.25rem 1rem;

  .flex-gap {
    display: flex;
    gap: 1.25rem;

    /* display: grid;
    grid-template-columns: repeat(auto-fill,minMax(200px,1fr));
    width: 100%;
    padding: 1rem;
    column-gap: 1rem; */
  }

  .flex-gap-padding {
    display: flex;
    gap: 1.25rem;
    padding-top: 1.125rem;
  }

  @media screen and (max-width: 768px) {
    width: 95vw;

    .flex-gap, .flex-gap-padding{
      max-width: 100%;
      overflow-x: auto;
      text-overflow: clip;

      ::-webkit-scrollbar {
        width: 2px;
        height: 1px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 95%;
`;

export const Buttons = styled.div`
  display: flex;
  /* position: absolute; */
  padding-top: 2rem;
  float: right;
  gap: 1rem;
`;
