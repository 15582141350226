import React, { useEffect, useState } from "react";
import { Table } from "components/Table";
import { getUsers } from "routes/users";
import { GetColumns } from "./columns";
import { Modal } from "components/Modal";
import { RadioButtonsGroup } from "components/RadioButtonsGroup";
import { DataCity, DataCountry, DataRadios, DataSex, DataUf } from "./data";
import { filterInfoProps, UsersResponseData } from "./typings";
import { Input } from "components/Input";
import { Select } from "components/Select";
import { produce } from "immer";
import api from "services/api";
import { useAlert } from "context/useAlert";
import { useLocation } from "react-router-dom";
import { useDialog } from "hooks/dialog";
import { IPropsDialogModal } from "components/DialogModal";
import { HiOutlineCheck, HiOutlineX } from "react-icons/hi";

export function Users() {
    const { showAlert } = useAlert();
    const { state } = useLocation();
    const [users, setUsers] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [plan, setPlan] = useState('');
    const ImgEvent = "https://struko.s3.sa-east-1.amazonaws.com/";
    const [filterInfo, setFilterInfo] = useState<filterInfoProps>({
        name: "",
        location: state?.state ? state?.state : "",
        sex: "",
        city: "",
        subscription: state?.subscription ? state?.subscription : "",
        initialDate: "",
        finalDate: "",
        role: "",
        page: 1,
        limit: 5,
        count: 5,
    });
    const [toEdit, setToEdit] = useState({
        id: null,
        courtesy: null,
        currentPlan: null,
    });

    async function handleEditPlan() {
        if (toEdit.courtesy) {
            try {
                await api.post(`/subscriptions/courtesy/${toEdit.id}`);
                showAlert({
                    severety: "success",
                    title: "O plano foi editado para Cortesia com sucesso!",
                });
                getUsers(
                    filterInfo.name,
                    filterInfo.location,
                    filterInfo.sex,
                    filterInfo.subscription,
                    filterInfo.initialDate,
                    filterInfo.finalDate,
                    filterInfo.role,
                    filterInfo.page,
                    filterInfo.limit,
                    filterInfo.city
                )
                    .then((response) => {
                        console.log(response.users);
                        setUsers(response.users);
                        setFilterInfo(
                            produce(filterInfo, (draft) => {
                                draft.count = response.count;
                            })
                        );
                        setIsLoading(false);
                    })
                    .catch(() => setIsLoading(false));
            } catch (e) {
                showAlert({
                    severety: "error",
                    title: "Algo deu errado e o plano não foi atualizado.",
                });
            } finally {
                setIsOpenModal(false);
            }
        } else {
            try {
                await api.patch(`/subscriptions/courtesy/${toEdit.id}`);
                showAlert({
                    severety: "success",
                    title: "O plano foi editado para Gratuito com sucesso!",
                });
                getUsers(
                    filterInfo.name,
                    filterInfo.location,
                    filterInfo.sex,
                    filterInfo.subscription,
                    filterInfo.initialDate,
                    filterInfo.finalDate,
                    filterInfo.role,
                    filterInfo.page,
                    filterInfo.limit,
                    filterInfo.city
                )
                    .then((response) => {
                        console.log(response.users);
                        setUsers(response.users);
                        setFilterInfo(
                            produce(filterInfo, (draft) => {
                                draft.count = response.count;
                            })
                        );
                        setIsLoading(false);
                    })
                    .catch(() => setIsLoading(false));
            } catch (e) {
                showAlert({
                    severety: "error",
                    title: "Algo deu errado e o plano não foi atualizado.",
                });
            } finally {
                setIsOpenModal(false);
            }
        }
    }

    function handleChangePage(
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) {
        setFilterInfo(
            produce(filterInfo, (draft) => {
                draft.page = newPage + 1;
            })
        );
        getUsers(
            filterInfo.name,
            filterInfo.location,
            filterInfo.sex,
            filterInfo.subscription,
            filterInfo.initialDate,
            filterInfo.finalDate,
            filterInfo.role,
            newPage + 1,
            filterInfo.limit,
            filterInfo.city
        ).then((response) => {
            setUsers(response.users);
        });
    }

    function handleChangeRowsPerPage(event: any) {
        setFilterInfo(
            produce(filterInfo, (draft) => {
                draft.limit = parseInt(event.target.value, 10);
                draft.page = 1;
            })
        );
        getUsers(
            filterInfo.name,
            filterInfo.location,
            filterInfo.sex,
            filterInfo.subscription,
            filterInfo.initialDate,
            filterInfo.finalDate,
            filterInfo.role,
            1,
            parseInt(event.target.value, 10),
            filterInfo.city
        ).then((response) => {
            setUsers(response.users);
        });
    }

    const columnsMethods = {
        edit: (id: string, currentPlan: string,type: string) => {
            console.log(type)
            setToEdit((e) => {
                e.id = id;
                e.currentPlan = currentPlan;
                return e;
            });
            setPlan(type);
            setIsOpenModal(true);
        },
        delete: (data: UsersResponseData) => {
            handleSwitchStatusDialog(data);
        },
    };

    const columns = GetColumns(columnsMethods as any);

    function handleFilterUsers() {
        getUsers(
            filterInfo.name,
            filterInfo.location,
            filterInfo.sex,
            filterInfo.subscription,
            filterInfo.initialDate,
            filterInfo.finalDate,
            filterInfo.role,
            1,
            filterInfo.limit,
            filterInfo.city
        ).then((response) => {
            setFilterInfo(
                produce(filterInfo, (draft) => {
                    draft.count = response.count;
                    draft.page = 1;
                })
            );
            setUsers(response.users);
        });
    }

    useEffect(() => {
        
        getUsers(
            filterInfo.name,
            filterInfo.location,
            filterInfo.sex,
            filterInfo.subscription,
            filterInfo.initialDate,
            filterInfo.finalDate,
            filterInfo.role,
            filterInfo.page,
            filterInfo.limit,
            filterInfo.city
        )
            .then((response) => {
                console.log(response);
                setUsers(response.users);
                setFilterInfo(
                    produce(filterInfo, (draft) => {
                        draft.count = response.count;
                    })
                );
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    }, []);

    // ------------------------------------
    // modal config
    const { setInfoModal, closeDialog } = useDialog();

    const handleSwitchStatusDialog = (element: UsersResponseData) => {
        console.log(element);
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                type: `${element.status == 1 ? "error" : "success"}`,
                title: {
                    text: `${
                        element.status == 1 ? "Inativar" : "Ativar"
                    } Usuário`,
                    icon:
                        element.status == 1 ? (
                            <HiOutlineX size={28} />
                        ) : (
                            <HiOutlineCheck size={28} />
                        ),
                },
                description: `Deseja realmente ${
                    element.status == 1 ? "Inativar" : "Ativar"
                } o Usuário?`,
                secondarybutton: {
                    text: "Cancelar",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "dialog-cancelar",
                },
                primarybutton: {
                    text: `${element.status == 1 ? "Inativar" : "Ativar"}`,
                    onClick: () => {
                        deleteUsers(element);
                    },
                    id: "dialog-switch",
                    type: `${element.status == 1 ? "error" : "success"}`,
                },
            },
        };
        setInfoModal(info);
    };

    const deleteUsers = async (element: UsersResponseData) => {
        try {
            await api.patch(`/users/${element.id}`, {
                status: element.status == 1 ? 0 : 1,
            });

            successDialog(element);
        } catch (error) {
            errorDialog(element);
            console.log(error);
        } finally {
            getUsers(
                filterInfo.name,
                filterInfo.location,
                filterInfo.sex,
                filterInfo.subscription,
                filterInfo.initialDate,
                filterInfo.finalDate,
                filterInfo.role,
                filterInfo.page,
                filterInfo.limit,
                filterInfo.city
            )
                .then((response) => {
                    console.log(response);
                    setUsers(response.users);
                    setFilterInfo(
                        produce(filterInfo, (draft) => {
                            draft.count = response.count;
                        })
                    );
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    };

    const successDialog = (element: UsersResponseData) => {
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                title: {
                    text: `O Usuário foi ${
                        element.status == 1 ? "inativado" : "ativado"
                    } com sucesso!`,
                    icon: <HiOutlineCheck size={28} />,
                },
                description: `O Usuário ${element.fullName} foi ${
                    element.status == 1 ? "inativado" : "ativado"
                } com sucesso na plataforma`,
                primarybutton: {
                    text: "Ok",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "ok",
                    type: "success",
                },
            },
        };
        setInfoModal(info);
    };

    const errorDialog = (element: UsersResponseData) => {
        const info: IPropsDialogModal = {
            modal: {
                status: true,
                type: "error",
                title: {
                    text: `Falha ao ${
                        element.status == 1 ? "inativar" : "ativar"
                    }!`,
                    icon: <HiOutlineX size={28} />,
                },
                description: `Houve um erro ao ${
                    element.status == 1 ? "inativar" : "ativar"
                } o consultor ${
                    element.fullName
                } na plataforma, contate o administrador.`,
                primarybutton: {
                    text: "Ok",
                    type: "error",
                    onClick: () => {
                        closeDialog();
                    },
                    id: "ok",
                },
            },
        };
        setInfoModal(info);
    };

    return (
        <>
            <Modal
                title="Alterar plano"
                subTitle="do usuário"
                icon="text_snippet"
                state={isOpenModal}
                setState={setIsOpenModal}
                onClick={() => handleEditPlan()}
                container={
                    <RadioButtonsGroup
                        onChange={(ev) =>
                            setToEdit((e) => {
                                e.courtesy = ev.target.value === "Cortesia";
                                return e;
                            })
                        }
                        defaultValue={plan}
                        data={DataRadios}
                    />
                }
            />

            <Table
                title={"Usuários Struko"}
                subtitle={"Visualize os usuários do aplicativo"}
                columns={columns}
                data={users}
                isLoading={isLoading}
                TablePaginationActionsProps={{
                    page: (filterInfo.page as any) - 1,
                    count: filterInfo.count,
                    rowsPerPage: filterInfo.limit,
                    handleChangeRowsPerPage: handleChangeRowsPerPage,
                    onPageChange: handleChangePage,
                }}
                withoutButtons
                filterProps={{
                    container: (
                        <>
                            <div className="flex-gap">
                                <Input
                                    isSmall
                                    label="Usuário"
                                    value={filterInfo.name}
                                    onChange={(e: any) =>
                                        setFilterInfo(
                                            produce(filterInfo, (draft) => {
                                                draft.name = e.target.value;
                                            })
                                        )
                                    }
                                    placeholder="Informe o nome do usuário"
                                    width={"20.9375rem"}
                                />
                                <Select
                                    isSmall
                                    label="Tipo de Plano"
                                    optionsData={DataRadios}
                                    value={filterInfo.subscription}
                                    onChange={(e: any) => {
                                        console.log(
                                            produce(filterInfo, (draft) => {
                                                draft.subscription =
                                                    e.target.value;
                                            })
                                        );
                                        setFilterInfo(
                                            produce(filterInfo, (draft) => {
                                                draft.subscription =
                                                    e.target.value;
                                            })
                                        );
                                    }}
                                    width={"15.313rem"}
                                />
                            </div>
                            <div className="flex-gap-padding">
                                {/* <Select
                  isSmall
                  label='País'
                  optionsData={DataCountry}
                  width={'10rem'}
                /> */}

                                <Select
                                    isSmall
                                    label="UF"
                                    optionsData={DataUf}
                                    value={filterInfo.location}
                                    onChange={(e: any) =>
                                        setFilterInfo(
                                            produce(filterInfo, (draft) => {
                                                draft.location = e.target.value;
                                            })
                                        )
                                    }
                                    width={"7.75rem"}
                                />

                                <Input
                                    isSmall
                                    label="Cidade"
                                    value={filterInfo.city}
                                    onChange={(e: any) =>
                                        setFilterInfo(
                                            produce(filterInfo, (draft) => {
                                                draft.city = e.target.value;
                                            })
                                        )
                                    }
                                    placeholder="Informe a cidade do usuário"
                                    // optionsData={DataCity}
                                    width={"14rem"}
                                />

                                <Select
                                    isSmall
                                    label="Sexo"
                                    optionsData={DataSex}
                                    value={filterInfo.sex}
                                    onChange={(e: any) =>
                                        setFilterInfo(
                                            produce(filterInfo, (draft) => {
                                                draft.sex = e.target.value;
                                            })
                                        )
                                    }
                                    width={"12rem"}
                                />
                            </div>
                        </>
                    ),
                    clearOnClick: () =>
                        setFilterInfo({
                            name: "",
                            location: "",
                            sex: "",
                            subscription: "",
                            initialDate: "",
                            finalDate: "",
                            role: "",
                            city: "",
                            page: 1,
                            limit: filterInfo.limit,
                            count: filterInfo.count,
                        }),
                    filterOnClick: () => handleFilterUsers(),
                }}
            />
        </>
    );
}
