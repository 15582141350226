import styled from 'styled-components'
import { font } from 'utils/fonts'
import { SideBarProps } from './typings'

export const Sidebar = styled.div`
  width: 11.9375rem;
  min-height: 97vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${props => props.theme.surface};
  gap: 1rem;

  @media screen and (max-width: 1200px) {
    position: absolute;
    transition: all 0.25s ease-in-out;
    box-shadow: 7px 4px 11px #00000045;
    z-index: 100;

    &.isClosed {
      left: -1000px;
    }
    
    &.isOpen {
      left: 0;
    }
  }
`

export const Option = styled.div<SideBarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2.4375rem 0 1.0625rem;
  margin-top: 1rem; //${props => props.hasMarginTop && '2.59375rem'}

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    text-transform: none;
    margin-left: -0.5rem;
    color: ${props => props.theme.primary};
  }

  .material-icons {
    color: ${props =>
      props.isActive
        ? props => props.theme.primary
        : props => props.theme.textInput};
  }
`

export const Title = styled.span<SideBarProps>`
  font-size: ${font.size14};
  color: ${props =>
    props.isActive
      ? props => props.theme.primary
      : props => props.theme.textInput};
  margin-left: 0.6875rem;
  text-align: start;
  width: 6.9375rem;
`
