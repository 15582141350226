import React, { useState } from 'react';
import * as S from './styles';
import { IconButton } from '@mui/material';
import { InputProps } from './typings';
import MaterialIcon from '@material/react-material-icon';

export function InputTraining({
	label,
	width,
	height,
	isPassword,
	type,
	hasError,
	maxLength,
	marginLeft,
	isSmall,
	customPadding,
	marginRight,
	color,
	containerWidth,
	flex,
	...props
}: InputProps) {
	const [showPassword, setShowPassord] = useState(false);

	return (
		<>
			<S.InputContainer
				flex={flex}
				marginLeft={marginLeft}
				marginRight={marginRight ? marginRight : '16px'}
				containerWidth={containerWidth}>
				<S.StyledLabel>{label}</S.StyledLabel>
				<S.InputContent>
					<S.Input
						style={
							customPadding && {
								padding: '1.5rem 1rem',
							}
						}
						width={width ? width : '100%'}
						height={isSmall ? '1.9375rem' : height}
						hasError={hasError}
						type={
							isPassword
								? !showPassword
									? 'password'
									: 'text'
								: type || 'text'
						}
						maxLength={maxLength ? maxLength : 100}
						isPassword={isPassword}
						{...props}
					/>

					{isPassword && (
						<S.ShowPassContainer>
							<IconButton
								aria-label={
									showPassword
										? 'Mostrar senha'
										: 'Ocultar senha'
								}
								onClick={() => setShowPassord(!showPassword)}
								edge="end">
								<MaterialIcon
									icon={
										showPassword
											? 'visibility_off'
											: 'visibility'
									}
								/>
							</IconButton>
						</S.ShowPassContainer>
					)}
				</S.InputContent>
			</S.InputContainer>
		</>
	);
}
