export const loadingData = [
  {
    primary: 'isLoading',
    secondary: 'isLoading',
    thirty: 'isLoading',
    fourthly: 'isLoading',
    fifthy: 'isLoading'
  },
  {
    primary: 'isLoading',
    secondary: 'isLoading',
    thirty: 'isLoading',
    fourthly: 'isLoading',
    fifthy: 'isLoading'
  },
  {
    primary: 'isLoading',
    secondary: 'isLoading',
    thirty: 'isLoading',
    fourthly: 'isLoading',
    fifthy: 'isLoading'
  },
  {
    primary: 'isLoading',
    secondary: 'isLoading',
    thirty: 'isLoading',
    fourthly: 'isLoading',
    fifthy: 'isLoading'
  },
  {
    primary: 'isLoading',
    secondary: 'isLoading',
    thirty: 'isLoading',
    fourthly: 'isLoading',
    fifthy: 'isLoading'
  }
]

export const loadingColumns = [
  {
    field: 'primary',
    title: 'isLoading'
  },
  {
    field: 'secondary',
    title: 'isLoading'
  },
  {
    field: 'thirty',
    title: 'isLoading'
  },
  {
    field: 'fourthly',
    title: 'isLoading'
  },
  {
    field: 'fifthy',
    title: 'isLoading'
  }
]
