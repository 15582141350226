import styled from 'styled-components'
import { font } from 'utils/fonts'
import { TextProps } from './typings'

export const Title = styled.h2<TextProps>`
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.isBold ? font.size24 : font.size20};
  color: ${props => (props.color ? props.color : props.theme.header)};
  font-weight: ${props =>
    props.fontWeight
      ? props.fontWeight
      : props.isBold
      ? font.weightStrong
      : font.weightLight};
  text-align: center;
  width: ${props => props.width};
`
