export const yearCompareData = async (data: any) => {
    const progress: number[] = [];
    const conclude: number[] = [];
    const date: string[] = [];
    Object.keys(data).forEach(function (key) {
        progress.push(data[key].em_andamento);
        conclude.push(data[key].concluidos);
        date.push(key);
    });

    console.log(progress, conclude, date);
    return {
        progress,
        conclude,
        date,
    };
};

export const clientsData = async (data: any) => {
    const created: number[] = [];
    const date: string[] = [];
    Object.keys(data).forEach(function (key) {
        created.push(data[key]);
        date.push(key.split("-")[1]);
        console.log(data[key], key);
    });

    return {
        created,
        date,
    };
};
