import { AiOutlineCompass } from 'react-icons/ai';
import { HiOutlineFire } from 'react-icons/hi';
import * as S from './styles';
import { IProps } from './typings';
import { Skeleton } from '@mui/material';
import { ReactComponent as NotImage } from './NotImage.svg';

export function CardTraining({ data }: IProps) {
	const { title, date, distance, calories, src, alt } = data;

	return (
		<>
			{false ? (
				<Skeleton
					animation="wave"
					width={'18.5vw'}
					variant="rounded"
					height={'15vh'}></Skeleton>
			) : (
				<S.Container>
					{src ? (
						<S.RowTop
							src={src}
							alt={alt}
						/>
					) : (
						<NotImage />
					)}

					<S.RowBottom>
						<S.Title>{title}</S.Title>
						<p>Realizada em {date}</p>
						<div>
							<S.TrainingInfo>
								<AiOutlineCompass size={15} />
								<span>Distância:</span> {distance.toFixed(2)}km
							</S.TrainingInfo>
							<S.TrainingInfo>
								<HiOutlineFire size={15} />
								<span>Calorias:</span> {calories.toFixed(2)}cal
							</S.TrainingInfo>
						</div>
					</S.RowBottom>
				</S.Container>
			)}
		</>
	);
}
