import styled from 'styled-components';
import { font } from 'utils/fonts';
import { ButtonProps } from './typings';

export const ButtonContainer = styled.div<ButtonProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	box-shadow: none !important;

	.MuiButton-containedSizeMedium {
		background: ${(props) =>
			props.isWhite ? props.theme.surface : props.theme.primary};
		width: ${(props) => (props.width ? props.width : 'auto')};
		height: ${(props) => (props.height ? props.height : 'auto')};
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.25rem;
		gap: 0.3rem;
		box-shadow: none;
		border: none;
		font-weight: ${(props) =>
			props.fontWeight ? props.fontWeight : font.weightLight};
		font-size: ${(props) =>
			props.fontSize
				? props.fontSize
				: props.isSmall
				? font.size12
				: font.size16};
		color: ${(props) =>
			props.isWhite ? props.theme.primary : props.theme.surface};
		word-break: break-word;
		font-family: 'Poppins', sans-serif;
		text-transform: none;
		border: ${(props) =>
			props.isWhite ? `1px solid ${props.theme.primary}` : ''};
	}

	.MuiButton-containedSizeMedium:hover {
		background: ${(props) =>
			props.isWhite ? props.theme.surface : props.theme.primaryHover};
		box-shadow: none;
		color: ${(props) => props.isWhite && props.theme.primaryHover};
		filter: brightness(0.95);
	}

	.MuiButton-containedSizeMedium:active {
		box-shadow: none;
	}

	.material-icons {
		color: ${(props) =>
			props.isWhite ? props.theme.primary : props.theme.surface};
		transform: ${(props) => props.isSmall && 'scale(0.8)'};
	}
`;
