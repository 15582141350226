import styled from "styled-components";

export const InputsTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minMax(250px, 1fr));
    column-gap: 1rem;

    input, label, select, div[role="presentation"]{
      width: 100%!important;
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  /* align-items: flex-end; */

  flex-direction: column;
  align-items: flex-start;
`;

export const SpanTextPhotoEvent = styled.span`
  font-size: 0.875rem;
  color: #767676;
  font-weight: 400;
  margin-bottom: 0.4rem;
`;

export const ButtonSearch = styled.button`
  width: 6.375rem;
  height: 3rem;

  border-radius: 4px 0 0 4px;

  background: #fdf7da;
  color: #ffbd12;
  border: none;
`;

export const InputsMidContainer = styled.div`
  padding-bottom: 20px;

  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minMax(250px, 1fr));
    column-gap: 1rem;

    input, label, select, div[role="presentation"]{
      width: 100%!important;
    }

    #city {
      height: 3rem!important;
    }
  }
`;

export const InputImgEvent = styled.input`
  background: #eef0f8;
  width: 14rem;
  height: 1.9375rem;
  /* border-radius: 0.25rem; */
  border-radius: 0 4px 4px 0;
  border: none;
  outline-color: #ffbd12;
  padding: 1.5rem;
`;

export const InputsBottomContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
  }

  @media screen and (max-width: 1500px) {
    display: grid;
    grid-template-columns: 1fr;

    input, label, select, div[role="presentation"]{
      width: 100%!important;
    }
  }
`;

export const ContainerButtonRegister = styled.div`
  display: flex;
  justify-content: end;

  width: 94%;
`;

export const ButtonRegisterEvent = styled.button`
  background: #ffbd12;
  color: #fff;
  border: none;
  height: 2rem;
  border-radius: 4px;
`;
