import React, { createContext, useState } from "react";
import { IAuthProvider, IContext, IUser } from "./typings";
import { Login } from "routes/auth";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
    const [user, setUser] = useState<IUser | null>(null);
    const token =
        localStorage.getItem("u") &&
        String(JSON.parse(localStorage.getItem("u") as any).t);

    const isAuthenticated = !!token;

    async function authenticate(email: string, password: string) {
        const response: any = await Login(email, password);

        setUser({
            firstName: response.data.user.firstName,
            fullName: response.data.user.fullName,
            email: response.data.user.email,
            avatar: response.data.user.picture,
            t: response.data.accessToken,
        });

        localStorage.setItem(
            "u",
            JSON.stringify({
                firstName: response.data.user.firstName,
                fullName: response.data.user.fullName,
                email: response.data.user.email,
                avatar: response.data.user.picture,
                t: response.data.accessToken,
            })
        );
    }

    return (
        <AuthContext.Provider
            value={{
                ...user,
                isAuthenticated,
                authenticate,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
