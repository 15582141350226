import * as Styles from "./styles";
import { AiOutlineRight } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { ApexChart } from "components/Graphic";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "context/useAlert";
import { lastDayOfMonth } from "date-fns";
import api from "../../services/api";
import { clientsData, yearCompareData } from "./graphDatas";
import { SelectFilter } from "components/SelectFilter";
import { StateTables } from "./table";
import { Skeleton } from "@mui/material";

interface IUserPlans {
    cortesia: number;
    gratuito: number;
    s;
    premium: number;
    success: boolean;
}

interface DashboardProps {
    className?: string;
}
interface SelectValues {
    name: string;
    value: number;
}
interface ClientsValues {
    created: number[];
    date: string[];
}

export const Dashboard = (props: DashboardProps) => {
    const { showAlert } = useAlert();
    const navigate = useNavigate();

    const mesAtual = new Date().getMonth();
    const anoAtual = new Date().getFullYear();

    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [usersPlans, setUsersPlans] = useState<IUserPlans>();
    const [years, setYears] = useState<SelectValues[]>([]);
    const [months, setMonths] = useState<SelectValues[]>([]);
    const [totalNewClients, setTotalNewClients] = useState<number>(0);
    const [clientsMonth, setClientsMonth] = useState<number>(mesAtual);
    const [clientsDate, setClientsDate] = useState<ClientsValues>({
        created: [],
        date: [],
    });

    const handleIconClick = (plan: string) => {
        navigate("/usuarios", { state: { subscription: plan } });
    };

    const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const anoInicial = 2023;

    const getUsers = async () => {
        try {
            const { data } = await api.get("/dashboard/subscriptionTypes");
            setUsersPlans(data);
        } catch (error) {
            showAlert({
                severety: "error",
                title: "Ops, algo deu errado.",
            });
        }
    };

    const getFilters = useCallback(async () => {
        try {
            const mesesAteAtual = meses.filter((_, index) => index <= mesAtual);
            setMonths(
                mesesAteAtual.map((e, index) => ({
                    name: index == mesAtual ? `${e} (atual)` : e,
                    value: index,
                }))
            );
            const anos: SelectValues[] = [];

            for (let ano = anoInicial; ano <= anoAtual; ano++) {
                anos.push({
                    name: String(ano),
                    value: ano - 2023,
                });
            }
            setYears(anos);
            // await handleCompareServices(anoAtual);
            // await handleServices(mesAtual);
            await handleClients(mesAtual);

            // setLoading(false);
        } catch {
            showAlert({
                severety: "error",
                title: "Ops, algo deu errado.",
            });
        }
    }, []);

    const handleClients = async (date: number) => {
        try {
            setLoading(true);
            setClientsMonth(date);
            const lastDay = await lastDayOfMonth(new Date(anoAtual, date, 1));
            const { data } = await api.get(
                `/dashboard/newClients?date=${anoAtual}-${date}-${1}`
            );
            setTotalNewClients(data.newClients);
            const clientsDataResult = await clientsData(data.dataMonth);
            setClientsDate(clientsDataResult);
        } catch (e: unknown) {
            console.log(e);
            showAlert({
                severety: "error",
                title: "Ocorreu um erro ao buscar informações sobre novos clientes.",
            });
        } finally {
            setLoading(false);
        }
    };

    const mountPage = useCallback(async () => {
        setLoading(true);
        await getUsers();
        await getFilters();
        setLoading(false);
    }, []);

    useEffect(() => {
        const isMounted = true;

        if (!isMounted) return;

        // setCurrentPage(0);
        mountPage();
    }, [mountPage]);

    return (
        <>
            <Styles.GridContainer>
                {loading ? (
                    <>
                        <div className="skeleton">
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </div>
                        <div className="skeleton">
                            <Skeleton />
                            <Skeleton animation="wave" />
                            <Skeleton animation={false} />
                        </div>
                    </>
                ) : (
                    <>
                        <Styles.GridItem>
                            <div className="top">
                                <div className="titles">
                                    <span className="title">
                                        Usuário por plano
                                    </span>
                                    <h4 className="description">
                                        Confira a contagem de usuários por plano
                                    </h4>
                                </div>
                            </div>
                            <div className="itemsList">
                                <div className="item">
                                    <h5 className="number">
                                        {usersPlans?.premium || 0}
                                    </h5>
                                    <div className="desc">Premium</div>
                                    <AiOutlineRight
                                        className="goTo"
                                        size={20}
                                        title="Ir para a tela de Premium"
                                        onClick={() =>
                                            handleIconClick("Premium")
                                        }
                                    />
                                </div>

                                <div className="item">
                                    <h5 className="number">
                                        {usersPlans?.cortesia || 0}
                                    </h5>
                                    <div className="desc">Cortesia</div>
                                    <AiOutlineRight
                                        className="goTo"
                                        size={20}
                                        title="Ir para a tela de Cortesia"
                                        onClick={() =>
                                            handleIconClick("Cortesia")
                                        }
                                    />
                                </div>

                                <div className="item last-item">
                                    <h5 className="number">
                                        {usersPlans?.gratuito || 0}
                                    </h5>
                                    <div className="desc">Grátis</div>
                                    <AiOutlineRight
                                        className="goTo"
                                        size={20}
                                        title="Ir para a tela de Grátis"
                                        onClick={() =>
                                            handleIconClick("Gratuito")
                                        }
                                    />
                                </div>
                            </div>
                        </Styles.GridItem>

                        <Styles.GridItem>
                            <div className="header">
                                <div className="titles">
                                    <h4 className="title">{totalNewClients}</h4>
                                    <span>Novos clientes</span>
                                </div>
                                <div className="select">
                                    <SelectFilter
                                        data={months}
                                        withoutDefaultOption
                                        id={"SelectMonth"}
                                        isFilled
                                        value={String(clientsMonth)}
                                        onChange={(e) => {
                                            //setSending(true);
                                            handleClients(Number(e)).then(
                                                () => {
                                                    // setLoading(false);
                                                    // setSending(false);
                                                }
                                            );
                                        }}
                                    />
                                </div>
                            </div>

                            <ApexChart
                                type="area"
                                id="1"
                                series={[
                                    {
                                        name: "Novos Clientes",
                                        data: clientsDate.created,
                                        color: "#FFBD12",
                                    },
                                ]}
                                categories={clientsDate.date}
                                name="prices"
                                width={"100%"}
                                showGrid
                            />
                        </Styles.GridItem>
                    </>
                )}
            </Styles.GridContainer>
            <StateTables />
        </>
    );
};
