import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: 100;
    top: -5rem;
    width: 50vw;
    box-shadow: rgb(0 0 0 / 27%) 7px 4px 11px;
    left: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.15s ease-in-out;

    &.visible {
      top: 3rem;
    }
  `}
`