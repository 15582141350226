import styled from "styled-components";

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem 0.5rem;
`

export const InputLine = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minMax(30vw, 1fr));
  column-gap: 2rem;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;

  input {
    height: 3rem;
    width: 100%;
  }

  @media screen and (max-width: 769px) {
    grid-template-columns: repeat(auto-fill, minMax(50vw, 1fr));
  }
`