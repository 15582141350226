import styled, { css } from "styled-components";

export const Container = styled.div`
    ${({ theme }) => css`
        width: 100%;
        height: 100%;
        padding: 1.8125rem 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
        gap: 1rem;
        justify-content: flex-start;
        align-items: center;
    `}
`;

export const GridContainer = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minMax(250px, 1fr));
        gap: 1rem;

        .skeleton {
            display: flex;
            flex-direction: column;
            background: ${theme.surface};
            padding: 1rem;
            width: 100%;
            gap: 0.75rem;
            border-radius: 8px;
        }

        @media screen and (min-width: 1200px) {
            grid-template-columns: 0.5fr 0.5fr;
        }
    `}
`;

export const GridItem = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: ${theme.surface};
        border-radius: 8px;
        padding: 0.5rem;

        .header {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 1rem 1rem 0;
            flex-wrap: wrap;

            .titles {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                flex: 1;

                .title {
                    font-size: 2rem;
                    line-height: 2rem;
                    font-weight: 700;
                    color: ${theme.primary};
                }

                span {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: ${theme.subtitle};
                }
            }
        }

        .graphContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -10px;
            width: 99%;
            max-width: 99%;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 140px !important;

            & > div {
                width: 100% !important;
                margin-bottom: -1.475rem;
            }

            /*div#apexcharts2 {
        margin-left: -9.815px;
      }

      div#apexcharts3 {
        margin-left: -11px;
      }

      @media screen and (max-width: 737px){
        width: 101.75%;
        max-width: 101.75%;
      }

      @media screen and (max-width: 550px){
        width: 102.75%;
        max-width: 102.75%;

        div#apexcharts1 {
          margin-left: -9.815px;
        }
      } */
        }

        .top {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            width: 100%;
            padding: 1rem;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.375rem;
                border-radius: 100%;
                background-color: ${theme.primary};

                svg {
                    width: 1.175rem;
                    height: 1.175rem;
                    color: ${theme.surface};
                }
            }

            .titles {
                display: flex;
                justify-content: center;
                flex-direction: column;
                flex: 1;

                .title {
                    color: ${theme.header};
                    font-size: 1.6rem;
                    font-weight: 700;
                }

                .description {
                    color: ${theme.subtitle};
                    font-size: 0.9rem;
                    font-weight: 400;
                }
            }
        }

        .itemsList {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            .item {
                padding-bottom: 0.75rem;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 0.75rem;
                width: 100%;
                cursor: pointer;
                border-bottom: 1px solid ${theme.separator};

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1.75rem;
                    height: 1.75rem;
                    color: ${theme.primary};
                }

                .number {
                    color: ${theme.primary};
                    font-size: 1.75rem;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                .desc {
                    font-size: 0.8rem;
                    color: ${theme.header};
                    font-weight: 500;
                    flex: 1;
                }

                .goTo {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 1rem;
                    height: 1rem;
                    color: ${theme.no_input};
                }
            }
            .last-item {
                border-bottom: 0px solid ${theme.no_input};
            }
            hr {
                width: 100%;
                height: 1px;
                border: 0;
                background-color: #f3f3f3;
            }
        }
    `}
`;

export const ContainerIcon = styled.span`
    color: #ffbd12;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ButtonEdit = styled.button`
    width: 2rem;
    height: 2rem;
    border-radius: 0.375rem;
    border: none;
    color: #ffbd12;
`;

export const ContainerTable = styled.div`
    ${({ theme }) => css`
        margin-top: 1rem;

        div table {
            width: 100%;
        }

        td {
            width: 50%;
        }

        h2 {
            color: ${theme.header};
            font-size: 1.6rem;
            font-weight: 700;
        }

        span {
            color: ${theme.subtitle};
            font-size: 0.9rem;
            font-weight: 400;
        }
    `}
`;
