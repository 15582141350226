import styled, { css } from "styled-components";

type TableHeaderProps = {
  width?: number;
  isHidden?: boolean;
};

export const Container = styled.div`
  height: 100%;

  padding: 1.875rem;
  border-radius: 0.75rem;
  background: ${(props) => props.theme.surface};
  max-width: 100%;
  justify-content: space-between;
  animation-name: transition;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;

  @keyframes transition {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
  }

  .filters {
    margin: 2.1rem 0 0 -26rem;
    z-index: 1;
    position: absolute;
  }

  .top-container {
    display: flex;
    justify-content: space-between;

    .top-buttons {
      display: flex;
      gap: 0.875rem;
    }

    .top-button {
      filter: ${(props) => props.theme.filterColor};
    }

    .top-button-after {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(327deg)
        brightness(102%) contrast(101%);
    }

    .titles {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
    }

    .button-style {
      background: #ffac12;
      color: #fff;

      width: 7.875rem;
      height: 2rem;

      border: none;
      border-radius: 4px;

      font-size: 12px;
    }

    @media screen and (max-width: 768px){
      flex-direction: column;
      row-gap: 1rem;

      div:has(button){
        &, button {
          width: 100%!important;
        }
      }
    }
  }

  .pagination-style {
    p{
      margin: 0;
    }
    .MuiTablePagination-displayedRows,
    .css-levciy-MuiTablePagination-displayedRows {
      position: relative;
      top: 0.01rem;
    }

    .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
      position: relative;
      top: 0.4rem;
    }

    svg {
      color: ${(props) => props.theme.primary};
    }

    .MuiTablePagination-actions {
      position: relative;
      top: 0.5rem;
    }

    .MuiTablePagination-root {
      color: ${(props) => props.theme.primary};
      display: flex;
      justify-content: center;
      margin-top: 3.5rem;
      overflow: hidden;

      .MuiInputBase-root,
      .MuiInputBase-colorPrimary,
      .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
        margin-bottom: 0.7rem;
      }

      .MuiButtonBase-root,
      .MuiIconButton-colorInherit,
      .MuiIconButton-sizeMedium,
      .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
        background: ${(props) => props.theme.primary};
        margin: 0 0 1rem 2rem;
      }

      .Mui-disabled,
      .Mui-disabled {
        background: ${(props) => props.theme.surface};
      }

      p:nth-child(2) {
        color: ${(props) => props.theme.subtle};
        display: flex;
        -webkit-flex-shrink: 0;
      }

      .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
        svg {
          filter: invert(100%) sepia(100%) saturate(200%) hue-rotate(152deg)
            brightness(1003%) contrast(103%);
        }
      }

      @media screen and (max-width: 768px) {
        .MuiToolbar-root{
          flex-direction: column;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: ${(props) => props.theme.header};
  font-weight: 700;
  margin-bottom: -0.2rem;
`;

export const SubTitle = styled.span`
  color: ${(props) => props.theme.subtitle};
  font-size: 0.75rem;
  font-weight: 500;
`;

export const Table = styled.table`
  margin-top: 1.5rem;
  table-layout: auto;

  @media screen and (max-width: 940px) {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    text-overflow: clip;
  }
`;

export const TableHead = styled.thead``;

export const List = styled.thead`
  display: flex;
  justify-content: space-between;
  width: 85vw;
  height: 4.5rem;
  margin-left: 5%;
`;

export const TableBody = styled.tbody`
  color: ${(props) => props.theme.textInput};
  max-width: 80%;

  .td-collapse {
    border-bottom: solid #e5eaee 1px;
  }
  .collapse-table {
    background: ${(props) => props.theme.bg};

    tr {
      border: none;
    }

    thead tr,
    tr:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.contract};
    }

    th {
      color: ${(props) => props.theme.primary};
    }

    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    margin-bottom: 21px;
  }
`;

export const Tr = styled.tr`
  border-bottom: solid #e5eaee 1px;
  justify-content: space-between;
  animation-name: transition;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;

  @keyframes transition {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
  }
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  animation-name: transition;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;

  @keyframes transition {
    0% {
      opacity: 0.2;
    }
    25% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.6;
    }
  }

  .icon-primarycolor {
    filter: ${(props) => props.theme.filterColor};
  }

  .icon-disabled {
    filter: invert(97%) sepia(23%) saturate(5612%) hue-rotate(180deg)
      brightness(75%) contrast(91%);
  }

  .default-arrow {
    filter: invert(68%) sepia(23%) saturate(177%) hue-rotate(191deg)
      brightness(96%) contrast(84%);
  }
`;

type Tdprops = {
  clickable?: boolean;
  isHidden?: boolean;
};

export const Td = styled.td<Tdprops>`
  padding: 1.5625rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  width: 35%;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
  visibility: ${({ isHidden }) => isHidden && "hidden"};
  opacity: ${({ isHidden }) => isHidden && 0};
  display: ${({ isHidden }) => isHidden && "none"};

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.375rem;
  }

  .tr-button {
    min-width: 6.75rem;
    height: 1.625rem;
    padding: 0.375rem 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.bg};
    border-radius: 0.25rem;

    color: ${(props) => props.theme.primary};
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
    cursor: default;

    svg,
    img {
      margin-right: 0.35rem;
    }
  }

  .tr-button-inactive {
    min-width: 6.75rem;
    height: 1.625rem;
    padding: 0.375rem 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background: ${(props) => props.theme.background};
    border-radius: 0.25rem;

    color: ${(props) => props.theme.noInput};
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

    svg,
    img {
      margin-right: 0.35rem;
    }
  }

  .tr-button-blue-active {
    min-width: 5rem;
    height: 1.625rem;
    padding: 0.375rem 0.75rem;

    justify-content: center;
    display: flex;
    align-items: center;

    background: ${(props) => props.theme.primary};
    border-radius: 0.25rem;

    color: ${(props) => props.theme.surface};
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 500;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    svg,
    img {
      margin-right: 0.35rem;
    }
  }

  .tr-button-blue {
    min-width: 6.75rem;
    height: 1.625rem;
    padding: 0.375rem 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background: ${(props) => props.theme.primary};
    border-radius: 0.25rem;

    color: ${(props) => props.theme.surface};
    font-size: 0.6875rem;
    font-weight: 500;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;

    img {
      width: 0.9375rem;
      border: none;

      filter: invert(100%) sepia(0%) saturate(14%) hue-rotate(326deg)
        brightness(103%) contrast(100%);
      user-select: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -ms-user-select: none;
    }
  }
`;

export const TrBody = styled.tr``;

export const Below = styled.div`
  width: 100%;
  height: 8rem;
  background: ${(props) => props.theme.bg};
  border-radius: 0 0 0.625rem 0.625rem;

  th {
    color: ${(props) => props.theme.primary};
  }

  .head-content {
    display: flex;
    gap: 4.9rem;
    border-bottom: solid ${(props) => props.theme.contract} 1px;
    width: 75vw;
  }

  .body-content {
    width: 70vw;
    margin: 1% 0 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .below-button {
    filter: ${(props) => props.theme.filterColor};
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }

  .buttons-below {
    float: right;
    user-select: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }
`;

export const Th = styled.th<TableHeaderProps>`
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0 1rem;
  color: ${(props) => props.theme.noInput};
  margin-left: 4.3%;
  height: 4rem;
  text-transform: uppercase;
  visibility: ${({ isHidden }) => isHidden && "hidden"};
  opacity: ${({ isHidden }) => isHidden && 0};
  display: ${({ isHidden }) => isHidden && "none"};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;
