import styled from 'styled-components'
import { SelectProps } from './typings'

export const SelectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: '2rem';
  margin-left: ${(props: SelectProps) => props.marginLeft};
`

export const Select = styled.select<SelectProps>`
  background: ${props => props.theme.background};
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  border-radius: 0.25rem;
  border: none;
  outline-color: ${props =>
    props.hasError ? props.theme.error : props.theme.primary};
  padding: 0 0.8rem 0 0.8rem;
`
