import styled from 'styled-components'

export const Login = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BoxContainer = styled.div`
  display: flex;
  width: 45rem;
  height: 27.1875rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.surface};
  border-radius: 0.9375rem;
`

export const Inputs = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.img`
  width: 30%;
  height: 15%;
  margin-bottom: 1.75rem;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;

  &::selection {
    background: transparent;
  }
`
