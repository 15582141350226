import React, { useState, useEffect } from "react";
import { Container } from "components/Container";
import Map from "components/GoogleMaps";
import api from "../../services/api";

export const UseAnalyze = () => {
    const [totalUsers, setTotalUsers] = useState<number | undefined>(undefined);

    useEffect(() => {
        const fetchTotalUsers = async () => {
            try {
                const response = await api.get("/users");
                const users = response.data;
                const totalUsers = users.count;
                setTotalUsers(totalUsers);
                // console.log(users);
            } catch (error) {
                console.error(error);
            }
        };

        fetchTotalUsers();
    }, []);

    return (
        <Container
            title={`Análise de Uso - ${
                totalUsers !== undefined
                    ? `${totalUsers} usuários`
                    : "Carregando..."
            }`}
            subTitle="Onde seus usuários estão atuando"
        >
            <Map />
        </Container>
    );
};
