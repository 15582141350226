import api from "services/api";
import cogoToast from "cogo-toast";

export async function getUsers(
  name?: string,
  location?: string,
  sex?: string,
  subscription?: string,
  initialDate?: string,
  finalDate?: string,
  role?: string,
  page?: number,
  limit?: number,
  city?: string
) {
  let response;
  try {
    const { data } = await api.get(
      `/users?name=${name}&state=${location}&city=${city}&sex=${sex}&subscription=${subscription}&initialDate=${initialDate}&finalDate=${finalDate}&role=${role}&page=${page}&limit=${limit}`
    );
    response = data;
  } catch (e) {
    cogoToast.error("Não foi possível carregar informações dos usuários.");
  }
  return response;
}
