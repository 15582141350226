import ApexCharts from "react-apexcharts";
import React from "react";

interface ApexProps {
    type:
        | "line"
        | "area"
        | "bar"
        | "histogram"
        | "pie"
        | "donut"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "treemap"
        | "boxPlot"
        | "candlestick"
        | "radar"
        | "polarArea"
        | "rangeBar";
    series: {
        name: string;
        data: number[];
        color?: string;
    }[];
    name: string;
    id: string;
    options?: ApexCharts.ApexOptions | undefined;
    showLabels?: boolean;
    showToolbar?: boolean;
    showGrid?: boolean;
    height?: string | number;
    width?: string | number;
    ref?: React.LegacyRef<ApexCharts> | undefined;
    title?: string;
    categories?: number[] | string[];
}

// export const ApexChart = (props: ApexProps) => {
//   const options = {
//     options: {
//       chart: {
//         id: props.id,
// toolbar: {
//   show: props.showToolbar || false
// },
// zoom: {
//   enabled: false
// }
//       },
//       dataLabels: {
//         enabled: false
//       },
// grid: {
//   show: props.showGrid || false,
//   padding: {
//     right: 0,
//     left: 0
//   }
// },
//       xaxis: {
// labels: {
//   show: props.showLabels || false
// },
// tooltip: {
//   enabled: false
// },
// categories: props.categories
//       },
//       yaxis: {
// labels: {
//   show: props.showLabels || false
// }
//       }
//     },
//     series: [
//       {
//         name: props.name,
//         data: props.series
//       }
//     ]
//   }
//   return (
//     <ApexCharts
//       //type='area'
//       // @ts-expect-error
//       series={options.series}
//       // series={props.series}
//       options={{
//         ...options.options,
//         title: {
//           align: 'left',
//           text: props.title || ''
//         }
//       }}

// ref={props.ref}
// type={props.type}
// height={props.height}
// width={props.width}
//       className="graphContainer"
//     />
//   )
// }

export class ApexChart extends React.Component<ApexProps> {
    constructor(props: ApexProps) {
        super(props);
        console.log(props);
        this.state = {
            width: props.width,
            series: props.series,
            options: {
                chart: {
                    type: props.type,
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false,
                            customIcons: [],
                        },
                    },
                },
                zoom: {
                    enabled: false,
                },
                dataLabels: {
                    enabled: false,
                },
                grid: {
                    show: props.showGrid || false,
                    padding: {
                        right: 0,
                        left: 0,
                    },
                    // borderColor: '#555C83',
                    row: {
                        colors: ["#FFF"],
                        opacity: 0.3,
                    },
                    column: {
                        colors: ["#FFF"],
                        opacity: 0.3,
                    },
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    labels: {
                        show: props.showLabels || false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    categories: props.categories,
                },
                yaxis: {
                    labels: {
                        show: props.showLabels || false,
                    },
                },
                fill: {
                    opacity: 1,
                },
                // tooltip: {
                //   y: {
                //     formatter: function (val: any) {
                //       return "$ " + val + " thousands"
                //     }
                //   }
                // }
            },
        };
    }

    render() {
        return (
            <ApexCharts
                // @ts-expect-error
                options={this.state.options}
                // @ts-expect-error
                series={this.state.series}
                // @ts-expect-error
                type={this.state.options.chart.type}
                height={300}
                // @ts-expect-error
                width={this.state.width}
                className="graphContainer"
            />
        );
    }
}
