import React, { useState, useRef, useEffect } from "react";

import { Container } from "components/Container";
import { AiOutlineCheck, AiOutlineLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import fileToBase64 from "utils/fileToBase64";
import { FormContainer, InputLine } from "./styles";
import { Input } from "components/Input";
import { useAlert } from "context/useAlert";
import api from "services/api";
import { MdPassword } from "react-icons/md";

interface IUser {
    user: {
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        id: string;
    };
}

export function RegisterADM() {
    const { showAlert } = useAlert();
    const history = useNavigate();
    const { id } = useParams();

    const [userData, setUserData] = useState({
        name: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        pass: "",
        confirmPass: "",
    });

    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    const handleRegister = async () => {
        const passRegex = new RegExp(/^.{6,16}$/);
        const emailRegex = new RegExp(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
        if (emailRegex.test(email)) {
            const payload = {
                firstName: name,
                lastName: lastName,
                email: email,
                password: pass,
                role: "ADMIN",
            };
            if (id) {
                if (pass && pass !== confirmPass) {
                    showAlert({
                        severety: "error",
                        title: "As senhas não coincidem.",
                    });
                    return;
                }

                if (email && email !== confirmEmail) {
                    showAlert({
                        severety: "error",
                        title: "Os emails não coincidem.",
                    });
                    return;
                }

                const { data } = await api.put(`/users/${id}`, payload);
                showAlert({
                    severety: "success",
                    title: `Administrador editado com sucesso`,
                });
                history("/administracao");
            } else {
                if (confirmEmail === email) {
                    if (passRegex.test(pass)) {
                        if (confirmPass === pass) {
                            try {
                                const { data } = await api.post(
                                    "/auth/register",
                                    payload
                                );
                                showAlert({
                                    severety: "success",
                                    title: `Administrador cadastrado com sucesso`,
                                });

                                history("/administracao");
                            } catch (error) {
                                showAlert({
                                    severety: "error",
                                    title: `Erro: ${error}`,
                                });
                            }
                        } else {
                            showAlert({
                                severety: "error",
                                title: "As senhas não coincidem.",
                            });
                        }
                    } else {
                        showAlert({
                            severety: "error",
                            title: "A senha deve conter entre 8 a 18 caracteres",
                        });
                    }
                } else {
                    showAlert({
                        severety: "error",
                        title: "Os emails não coincidem.",
                    });
                }
            }
        } else {
            showAlert({
                severety: "error",
                title: "Por favor informe um email valido!",
            });
        }
    };

    const mountUser = async () => {
        const { data } = await api.get<IUser>(`/users/${id}`);
        setName(data.user.firstName);
        setLastName(data.user.lastName);
        setEmail(data.user.email);
        setConfirmEmail(data.user.email);
    };

    useEffect(() => {
        if (id) {
            mountUser();
        }
    }, []);

    return (
        <Container
            title={`${id ? "Editar Administrador" : "Cadastrar Administrador"}`}
            hasBtns
            primaryButton={{
                label: `${
                    id ? "Editar Administrador" : "Cadastrar Administrador"
                }`,
                onClick: () => handleRegister(),
                disabled: false,
                icon: <AiOutlineCheck title="Confirmar" />,
            }}
            secondaryButton={{
                label: "Voltar",
                onClick: () => history("/administracao"),
                disabled: false,
                icon: <AiOutlineLeft title="Voltar" />,
            }}
            hasIconBack
            returnOnClick={() => history("/administracao")}
            //subTitle='Onde seus usuários estão atuando'
        >
            <FormContainer onSubmit={(e) => e.preventDefault()}>
                <InputLine>
                    <Input
                        label="Nome"
                        placeholder="Informe o nome do usuário "
                        value={name}
                        onChange={(e) => {
                            if (e.target.value.replace(/\s/g, "") != "") {
                                setName(e.target.value);
                            } else {
                                setName("");
                            }
                        }}
                    />
                    <Input
                        label="Sobrenome"
                        placeholder="Informe o sobrenome do usuário"
                        value={lastName}
                        onChange={(e) => {
                            if (e.target.value.replace(/\s/g, "") != "") {
                                setLastName(e.target.value);
                            } else {
                                setLastName("");
                            }
                        }}
                    />
                </InputLine>
                <InputLine>
                    <Input
                        label="Email"
                        placeholder="Informe o email do usuário"
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        label="Confirmar email"
                        placeholder="Informe novamente o email do usuário"
                        value={confirmEmail}
                        type="email"
                        onChange={(e) => setConfirmEmail(e.target.value)}
                    />
                </InputLine>
                <InputLine>
                    <Input
                        label="Senha"
                        placeholder="Informe o senha do usuário"
                        value={pass}
                        type="password"
                        onChange={(e) => setPass(e.target.value)}
                        isPassword
                    />
                    <Input
                        label="Confirmar senha"
                        placeholder="Informe novamente o senha do usuário"
                        value={confirmPass}
                        type="password"
                        onChange={(e) => setConfirmPass(e.target.value)}
                        isPassword
                    />
                </InputLine>
            </FormContainer>
        </Container>
    );
}
