import { Button } from 'components/Button';
import * as S from './styles';
import { IProps } from './types';

export function CardHeader({
	icon,
	title,
	primaryButton,
	secondaryButton,
}: IProps) {
	return (
		<S.Header>
			<S.StyledContainer>
				<div>
					<S.StyledIcon>{icon}</S.StyledIcon>
					<S.StyledTitle>{title}</S.StyledTitle>
				</div>
				<S.ContainerButton>
					{secondaryButton && (
						<Button
							onClick={secondaryButton.onClick}
							disabled={secondaryButton.disabled}
							title={secondaryButton.title}
							iconName={secondaryButton.startIcon}
							width={secondaryButton.width}
							isWhite
							isSmall></Button>
					)}

					{primaryButton && (
						<Button
							onClick={primaryButton.onClick}
							disabled={primaryButton.disabled}
							title={primaryButton.title}
							iconName={primaryButton.startIcon}
							width={primaryButton.width}
							isSmall></Button>
					)}
				</S.ContainerButton>
			</S.StyledContainer>
		</S.Header>
	);
}
