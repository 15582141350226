import { Button } from 'components/Button'
import React, { useState } from 'react'
import * as S from './styles'
import { FilterProps } from './typings'

export function Filter({ container, clearOnClick, filterOnClick }: FilterProps) {
  return (
    <S.Filter>
      {container}
      <S.ButtonsContainer>
        <S.Buttons>
          <Button
            onClick={clearOnClick}
            isWhite
            title='Limpar'
            width='6.888125rem'
            height='1.924375rem'
          />
          <Button
            onClick={filterOnClick}
            title='Filtrar'
            iconName='tune'
            width='6.888125rem'
            height='1.924375rem'
          />
        </S.Buttons>
      </S.ButtonsContainer>
    </S.Filter>
  )
}