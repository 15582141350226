import React from "react";
import * as S from "./styles";
import MaterialIcon from "@material/react-material-icon";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { SidebarOptions } from "./options";
import { SidebarOptionsProps } from "./typings";

interface SidebarProps {
    open: Boolean;
}

export function Sidebar({ open }: SidebarProps) {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <S.Sidebar className={open ? "isOpen" : "isClosed"}>
            {SidebarOptions.map((item: SidebarOptionsProps, index: number) => (
                <S.Option
                    key={index}
                    hasMarginTop={index === 0}
                    isActive={location.pathname === item.path}
                >
                    <Button onClick={() => navigate(item.path)}>
                        <MaterialIcon icon={item.icon} />
                        <S.Title isActive={location.pathname === item.path}>
                            {item.title}
                        </S.Title>
                    </Button>
                </S.Option>
            ))}
        </S.Sidebar>
    );
}
