import styled from "styled-components";

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: ${(props) => props.theme.background};
  outline: none;
  width: 2rem;
  height: 2rem;
  border-radius: 0.375rem;
  color: transparent;
  transition: all 0.25s ease-in-out;

  &:hover {
    filter: brightness(0.95);

    .material-icons{
      filter: brightness(1.1);
    }
  }

  .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    text-transform: none;
    width: 0.2rem;
    color: transparent;
  }

  .material-icons {
    color: ${(props) => props.theme.primary};
    transform: scale(70%);
  }
`;
