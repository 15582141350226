import styled from "styled-components";

export const DropDownUser = styled.div`
    .dropdownUserContainer {
        z-index: 80;
        background-color: #fff;
        width: 400px;
        border-radius: 10px;
        position: absolute;
        right: 0rem;

        .dropdownUserBox {
            display: flex;
            flex-direction: column;
            border: 1px solid #00000020;
            border-radius: 8px;

            .head {
                display: flex;
                padding: 0.75rem 1.75rem;
                color: #344767;
                text-decoration: none;
                padding-bottom: 0.75rem;
                border-bottom: 1px solid #e5eaee;

                img {
                    border-radius: 100%;
                    max-width: 72px;
                    max-height: 72px;
                    width: 100%;
                    height: 100%;
                    margin-right: 1rem;
                }
                span {
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;
                    h3 {
                        font-size: 1.175rem;
                        line-height: 1.375rem;
                        margin-bottom: 0.3rem;
                    }
                    small {
                        color: #a1a5b7;
                        line-height: 1.125rem;
                        font-size: 0.875rem;
                    }
                }
            }

            .contentNotifi {
                padding: 1.15rem 1.75rem;

                Link,
                a,
                .logout {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    color: #a1a5b7;
                    text-decoration: none;
                    cursor: pointer;
                    gap: 0.75rem;

                    :first-child {
                        margin-right: 0.5rem;
                    }
                    h4 {
                        font-size: 1.125rem;
                        line-height: 1.375rem;
                        margin-bottom: 0;
                        margin-right: 0.5rem;
                    }
                }
            }

            .red {
                a,
                Link,
                .logout {
                    color: #d65050;
                }
            }
        }
    }

    @media screen and (max-width: 560px) {
        .dropdownUserContainer {
            /* width: 95%; */
            /* top: 79px; */
            align-self: center;
        }
    }

    @media screen and (max-width: 375px) {
        .dropdownUserContainer {
            width: 100%;
            /* top: 79px; */
            align-self: center;
        }
    }
`;
