import React from 'react'
import * as S from './styles'
import { Label } from 'components/Label'
import { font } from 'utils/fonts'
import { OptionsProps, SelectProps } from './typings'

export function SelectLabel({
  label,
  width,
  height,
  hasError,
  marginLeft,
  isSmall,
  optionsData,
  state,
  setState,
  ...props
}:
  SelectProps) {

  return (
    <div>
      <S.SelectContainer marginLeft={marginLeft}>
        <Label marginBottom={'0.4rem'} fontSize={isSmall ? font.size14 : undefined} fontColor={isSmall ? '#767676' : undefined}>{label}</Label>

        <S.Select
          width={width}
          height={isSmall ? '1.9375rem' : height}
          hasError={hasError}
          value={state}
          onChange={(e: any) => setState(e.target.value)}
          {...props}
        >
          <option value="">
            Selecione uma opção
          </option>
          {optionsData.map((item: OptionsProps, index: number) => (
            <option key={index} value={item.label}>{item.label}</option>
          ))}
        </S.Select>

      </S.SelectContainer>
    </div>
  )
}