import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${(props) => props.theme.borderCard};

	padding: 16px;
	gap: 16px;
	border-radius: 8px;
	padding: 16px;

	width: 370px;
	height: 270px;
	max-width: 370px;
	max-height: 300px;
	min-width: 370px;
	min-height: 300px;
`;

export const RowTop = styled.img`
	margin-bottom: 4px;
	border-radius: 4px;

	max-width: 328.333px;
	max-height: 128px;
	min-width: 328.333px;
	min-height: 128px;

	width: 328.333px;
	height: 128px;
	object-fit: cover;
`;

export const RowBottom = styled.div`
	& div {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 24px;
	}

	& p {
		color: ${({ theme }) => theme.textCard};
		font-weight: 400;
		font-size: 12px;
	}
`;

export const Title = styled.h1`
	color: ${({ theme }) => theme.textCard};
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 4px;
`;

export const Date = styled.p`
	color: ${({ theme }) => theme.textCard};
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 4px;
`;

export const TrainingInfo = styled.p`
	color: ${({ theme }) => theme.textCard};
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 4px;

	span {
		color: ${({ theme }) => theme.textCard};
		font-size: 12px;
		font-weight: 700;
		margin-bottom: 4px;
	}
`;
