import { useEffect, useState } from "react";
import { CardHeader } from "components/CardHeader/Index";
import { CardTraining } from "components/CardTraining";
import { Container } from "components/Container";
import { InputTraining } from "components/InputTraining";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import * as S from "./styles";
import api from "services/api";
import { useAlert } from "context/useAlert";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "components/Select";
import StateCity from "../../JSON/State-City/estados-cidades.json";
import StateCityId from "../../JSON/State-City/estados-cidades2.json";
import { Skeleton } from "@mui/material";
import {format} from 'date-fns'

export function Profile() {
    const { showAlert } = useAlert();
    const navigate = useNavigate();
    const params = useParams();
    const id_user = params.id;

    const [dataTraining, setDataTraining] = useState([]);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [nickname, setNickname] = useState("");
    const [gender, setGender] = useState("");
    const [city, setCity] = useState("");
    const [stateUserInfo, setStateUserInfo] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [status, setStatus] = useState("");
    const [plan, setPlan] = useState("");
    const [criacao, setCriacao] = useState<any>("");
    const [birthDate, setBirthDate] = useState<any>("");
    const [isloadingProfile, setIsloadingProfile] = useState(true);
    const [isloadingTraining, setIsloadingTraining] = useState(true);

    interface IPayload {
        firstName: string;
        lastName: string;
        nickname: string;
        sex: string;
        city: string;
        state: string;
        country: string;
        birthDate: string;
        email: string;
        weight: string;
        height: string;
        city_id: number;
        plan?: string;
        status?: string;
        createdAt?: Date;
        birthData?: Date;
        
       
    }
   

    const stateBrasil = StateCity.estados.map((item) => ({
        value: item.sigla,
        label: item.sigla,
    }));

    const [cityBrasil, setCityBrasil] = useState([
        {
            value: StateCity.estados[0].cidades[0],
            label: StateCity.estados[0].cidades[0],
        },
    ]);

    const genderOptions = [
        { label: "Masculino", value: "M" },
        { label: "Feminino", value: "F" },
    ];

    useEffect(() => {
        getUserInfo();
        getTraining();
        // eslint-disable-next-line
    }, []);



    const filterCities = () => {
        var filteredCities = [];
        StateCity.estados.forEach((item) => {
            if (item.sigla === stateUserInfo) {
                item.cidades.forEach((cities) =>
                    filteredCities.push({
                        value: cities,
                        label: cities,
                    })
                );
                setCityBrasil(filteredCities);
            }
        });

        if (filteredCities.length > 0) {
            var validaty = false;
            filteredCities.forEach((item) => {
                if (item === city) validaty = true;
            });
            if (!validaty) setCity(filteredCities[0].value);
            else setCity(city);
        }
    };

    const getUserInfo = async () => {
        try {
            setIsloadingProfile(true);
            const data = await api.get(`/users/${id_user}`);

            const FormatDateComponent = (data:Date) => {
                const formattedDate = format(data, 'dd/MM/yyyy');
                return formattedDate;
            };

            const user = data.data.user;
            console.log(user);
            setFirstname(user.firstName);
            setLastname(user.lastName);
            setNickname(user.nickname);
            setGender(user.sex);

            setStateUserInfo(user.state);
            setCountry(user.country);
            setEmail(user.email);
            setHeight(user.height);
            setWeight(user.weight);
            const dateCriacao = FormatDateComponent(user.createdAt)
            setCriacao(dateCriacao);
            console.log(dateCriacao)
            const aniversario = FormatDateComponent(user.birthDate)
            setBirthDate(aniversario);
            if (user?.subscription?.status) {
                setStatus(
                    user.subscription.status === "active" ? "Ativo" : "Expirado"
                );
            } else {
                setStatus("Não definido");
            }
            if (user?.subscription?.plan?.name) {
                setPlan(user.subscription.plan.name);
            } else {
                setPlan("Não definido");
            }
            
            // filterCities();
            setCity(user.city);

            setIsloadingProfile(false);
        } catch (error) {
            showAlert({
                severety: "error",
                title: "Usuario não encontrado",
            });
            navigate("/usuarios");
            setIsloadingProfile(false);
        }
    };

    const getTraining = async () => {
        try {
            setIsloadingTraining(true);
            const data = await api.get(`users/${id_user}/workouts`);
            setDataTraining(data.data.workouts);
            setIsloadingTraining(false);
        } catch (error) {
            showAlert({
                severety: "error",
                title: error?.response?.data?.error,
            });
            setIsloadingTraining(false);
        }
    };

    const putUserInfo = async () => {
        try {
            const filterCity = StateCityId.cities.filter(
                (item) => item.name === city
            );
            const city_id = filterCity.length > 0 ? filterCity[0].id : null;

            let payload: IPayload = {
                firstName: firstname
                    ? firstname[0]?.toUpperCase() + firstname?.substring(1)
                    : "",
                lastName: lastname
                    ? lastname[0]?.toUpperCase() + lastname?.substring(1)
                    : "",
                nickname: nickname
                    ? nickname[0]?.toUpperCase() + nickname?.substring(1)
                    : "",

                sex: gender,
                city: city ? city[0]?.toUpperCase() + city?.substring(1) : "",
                state: stateUserInfo
                    ? stateUserInfo[0]?.toUpperCase() +
                      stateUserInfo?.substring(1)
                    : "",
                country: country
                    ? country[0]?.toUpperCase() + country?.substring(1)
                    : "",

                birthDate: birthDate,

                email: email ? email?.toLowerCase() : "",
                weight: weight,
                height: height,

                city_id: city_id,
            };
            if (plan !== "Não definido") {
                payload = {
                    ...payload,
                    plan: plan,
                };
            }
            if (status !== "Não definido") {
                payload = {
                    ...payload,
                    status: status,
                };
            }
            api.put(`/users/${id_user}`, payload);
            showAlert({
                severety: "success",
                title: "Usuário editado com sucesso",
            });
            backPage();
        } catch (error) {
            showAlert({
                severety: "error",
                title: error?.response?.data?.error,
            });
        }
    };

    const backPage = () => {
        navigate("/usuarios");
    };

    const dateTransform = (created: string, title: string) => {
        if (created) {
            var date = new Date(created);

            if (!title) {
                var hours = date.getHours();
                var period = null;

                if (hours > 3 && hours <= 12) {
                    period = "Treino Matutino";
                } else if (hours > 12 && hours <= 18) {
                    period = "Treino Vespertino";
                } else {
                    period = "Treino Noturno";
                }
            }
            var formatedDate = date.toLocaleDateString("pt-BR");
        }
        return [formatedDate, period];
    };
    console.log(city,2)
    return (
        <>
            {!(isloadingTraining === false && isloadingProfile === false) ? (
                <S.MainContainerSkeleton>
                    <Skeleton
                        animation="wave"
                        width={"auto"}
                        variant="rounded"
                        height={"15vh"}
                    ></Skeleton>

                    <Skeleton
                        animation="wave"
                        width={"auto"}
                        variant="rounded"
                        height={"7vh"}
                    ></Skeleton>

                    <Skeleton
                        animation="wave"
                        width={"auto"}
                        variant="rounded"
                        height={"7vh"}
                    ></Skeleton>

                    <Skeleton
                        animation="wave"
                        width={"auto"}
                        variant="rounded"
                        height={"7vh"}
                    ></Skeleton>

                    <S.ContainerSkeleton>
                        <Skeleton
                            animation="wave"
                            width={"auto"}
                            variant="rounded"
                            height={"20vh"}
                        ></Skeleton>

                        <S.SkeletonRow>
                            <Skeleton
                                animation="wave"
                                width={"18.5vw"}
                                variant="rounded"
                                height={"15vh"}
                            ></Skeleton>

                            <Skeleton
                                animation="wave"
                                width={"18.5vw"}
                                variant="rounded"
                                height={"15vh"}
                            ></Skeleton>

                            <Skeleton
                                animation="wave"
                                width={"18.5vw"}
                                variant="rounded"
                                height={"15vh"}
                            ></Skeleton>

                            <Skeleton
                                animation="wave"
                                width={"18.5vw"}
                                variant="rounded"
                                height={"15vh"}
                            ></Skeleton>
                        </S.SkeletonRow>
                    </S.ContainerSkeleton>
                </S.MainContainerSkeleton>
            ) : (
                <S.MainContainer>
                    <Container>
                        <CardHeader
                            icon={
                                <MdOutlineArrowBackIosNew
                                    size={32}
                                    fontWeight={800}
                                    onClick={backPage}
                                    style={{ cursor: "pointer" }}
                                />
                            }
                            title="Visualizar Usuário"
                            // primaryButton={{
                            //     title: " Confirmar Edição",
                            //     onClick: putUserInfo,
                            //     startIcon: "check",
                            //     width: "11.8rem",
                            // }}
                            secondaryButton={{
                                title: "Voltar",
                                onClick: backPage,
                                startIcon: "arrow_back_ios_new",
                            }}
                        />
                        <S.ContainerTable>
                            <S.RowTable>
                                <InputTraining
                                    disabled
                                    label="Nome"
                                    placeholder="Digite seu nome"
                                    value={firstname}
                                    onChange={(event) =>
                                        setFirstname(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    label="Sobrenome"
                                    placeholder="Digite seu sobrenome"
                                    value={lastname}
                                    onChange={(event) =>
                                        setLastname(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    label="Apelido"
                                    placeholder="Digite seu apelido"
                                    value={nickname}
                                    onChange={(event) =>
                                        setNickname(event.target.value)
                                    }
                                ></InputTraining>
                            </S.RowTable>

                            <S.RowTable>
                                <S.ContainerSelect>
                                    <Select
                                        label="Sexo"
                                        optionsData={genderOptions}
                                        state={gender}
                                        setState={setGender}
                                        multiple={false}
                                    />
                                </S.ContainerSelect>

                                {country.toUpperCase().trim() !== "BRASIL" ? (
                                    <InputTraining
                                        disabled
                                        label="Cidade"
                                        placeholder="Digite sua cidade"
                                        value={city}
                                        onChange={(event) =>
                                            setCity(event.target.value)
                                        }
                                    ></InputTraining>
                                ) : (
                                    <InputTraining
                                        disabled
                                        label="Cidade"
                                        value={city}
                                    ></InputTraining>
                                )}
                                {country.toUpperCase().trim() !== "BRASIL" ? (
                                    <InputTraining
                                        disabled
                                        label="Estado"
                                        placeholder="Digite seu estado"
                                        value={stateUserInfo}
                                        onChange={(event) =>
                                            setStateUserInfo(event.target.value)
                                        }
                                    ></InputTraining>
                                ) : (
                                    // <S.ContainerSelect>
                                    //     <Select
                                    //         disabled
                                    //         label="Estado"
                                    //         optionsData={stateBrasil}
                                    //         state={stateUserInfo}
                                    //         setState={setStateUserInfo}
                                    //         multiple={false}
                                    //     />
                                    // </S.ContainerSelect>
                                    <InputTraining
                                    disabled
                                    label="Estado"
                                    value={stateUserInfo}>

                                    </InputTraining>
                                )}

                                <InputTraining
                                    disabled
                                    label="Pais"
                                    placeholder="Digite seu pais"
                                    value={country}
                                    onChange={(event) =>
                                        setCountry(event.target.value)
                                    }
                                ></InputTraining>
                            </S.RowTable>
                            <S.RowTable>
                                <InputTraining
                                    disabled
                                    label="E-mail"
                                    placeholder="Digite seu e-mail"
                                    value={email}
                                    onChange={(event) =>
                                        setEmail(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    flex={0.3}
                                    label="Altura"
                                    placeholder="Altura"
                                    containerWidth={"45%"}
                                    value={height}
                                    onChange={(event) =>
                                        setHeight(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    flex={0.3}
                                    label="Peso"
                                    placeholder="Peso"
                                    containerWidth={"45%"}
                                    value={weight}
                                    onChange={(event) =>
                                        setWeight(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    flex={0.4}
                                    label="Status"
                                    placeholder="Seu status"
                                    containerWidth={"50%"}
                                    value={status}
                                    onChange={(event) =>
                                        setStatus(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    flex={0.5}
                                    label="Plano"
                                    placeholder="Seu plano"
                                    containerWidth={"50%"}
                                    value={plan}
                                    onChange={(event) =>
                                        setPlan(event.target.value)
                                    }
                                ></InputTraining>
                                
                            </S.RowTable>
                            <S.RowTable>
                                <InputTraining
                                    disabled
                                    flex={0.5}
                                    label="Data de criação"
                                    placeholder="Data de criação"
                                    containerWidth={"50%"}
                                    value={criacao}
                                    onChange={(event) =>
                                        setCriacao(event.target.value)
                                    }
                                ></InputTraining>
                                <InputTraining
                                    disabled
                                    flex={0.5}
                                    label="Data de nascimento"
                                    placeholder="Data de nascimento"
                                    containerWidth={"50%"}
                                    value={birthDate}
                                    onChange={(event) =>
                                        setBirthDate(event.target.value)
                                    }
                                ></InputTraining>
                            </S.RowTable>
                        </S.ContainerTable>
                    </Container>

                    <Container
                        title="Últimas atividades realizadas"
                        subTitle="Visualize as atividades do usuário"
                    >
                        <S.ContainerTraining>
                            {dataTraining.length > 0 ? (
                                dataTraining.map((item) => {
                                    const [date, title] = dateTransform(
                                        item.created,
                                        item.title
                                    );
                                    var data = {
                                        src: item.photo,
                                        alt: `Imagem do treino: ${
                                            item.title ? item.title : title
                                        }`,
                                        title: item.title ? item.title : title,
                                        date: date,
                                        distance: item.distance,
                                        calories: item.calories,
                                    };
                                    return (
                                        <CardTraining
                                            data={data}
                                            key={item._id}
                                        />
                                    );
                                })
                            ) : (
                                <S.Text>O usuario não possui treinos</S.Text>
                            )}
                        </S.ContainerTraining>
                    </Container>
                </S.MainContainer>
            )}
        </>
    );
}
